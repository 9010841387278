import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getServerError } from "../../utils/utils";
import {
  apiFirebaseToken,
  apiGetAllNotification,
  apiReadAllNotification,
  apiUpdateNotification,
} from "../../apis/notification";

export const firebaseToken = createAsyncThunk(
  "notification/firebaseToken",
  async (payload) => {
    try {
      const response = await apiFirebaseToken(payload);
      return response.data;
    } catch (err) {
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      throw new Error(errorMsg);
    }
  }
);

export const getAllNotification = createAsyncThunk(
  "notification/getAllNotification",
  async (payload) => {
    try {
      const response = await apiGetAllNotification(payload);
      return response.data;
    } catch (err) {
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      throw new Error(errorMsg);
    }
  }
);

export const updateNotification = createAsyncThunk(
  "notification/updateNotification",
  async (payload) => {
    try {
      const response = await apiUpdateNotification(payload);
      return response.data;
    } catch (err) {
      console.log(err, "err");
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      throw new Error(errorMsg);
    }
  }
);

export const readAllNotification = createAsyncThunk(
  "notification/readAllNotification",
  async (payload) => {
    try {
      const response = await apiReadAllNotification(payload);
      return response.data;
    } catch (err) {
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      throw new Error(errorMsg);
    }
  }
);

const initialNotificationState = {
  isNotificationFetched: false,
  newNotification: 0,
  firebaseTokenApi: { data: [], loading: false, error: null },
  getAllNotificationApi: { data: [], loading: false, error: null },
  updateNotificationApi: { data: [], loading: false, error: null },
  readAllNotificationApi: { data: [], loading: false, error: null },
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState: initialNotificationState,
  reducers: {},
  extraReducers: {
    [firebaseToken.pending]: (state) => {
      state.firebaseTokenApi.loading = true;
      state.firebaseTokenApi.error = null;
    },
    [firebaseToken.fulfilled]: (state, action) => {
      state.firebaseTokenApi.loading = false;
    },
    [firebaseToken.rejected]: (state, action) => {
      state.firebaseTokenApi.loading = false;
      state.firebaseTokenApi.error = action.error.message;
    },
    [getAllNotification.fulfilled]: (state, action) => {
      state.getAllNotificationApi.loading = false;
      state.getAllNotificationApi.data = action.payload;
      state.newNotification = action.payload.unread_msgs;
    },
    [getAllNotification.pending]: (state, action) => {
      state.isNotificationFetched = true;
      state.getAllNotificationApi.loading = true;
    },
    [getAllNotification.rejected]: (state, action) => {
      state.getAllNotificationApi.loading = false;
      state.getAllNotificationApi.error = action.error.message;
    },
    [updateNotification.fulfilled]: (state, action) => {
      state.updateNotificationApi.loading = false;
      state.updateNotificationApi.data = action.payload;
    },
    [updateNotification.pending]: (state, action) => {
      state.updateNotificationApi.loading = true;
    },
    [updateNotification.rejected]: (state, action) => {
      state.updateNotificationApi.loading = false;
      state.updateNotificationApi.error = action.error.message;
    },
    [readAllNotification.pending]: (state, action) => {
      state.readAllNotificationApi.loading = true;
    },
    [readAllNotification.fulfilled]: (state, action) => {
      state.readAllNotificationApi.loading = false;
      state.readAllNotificationApi.data = action.payload;
    },
    [readAllNotification.rejected]: (state, action) => {
      state.readAllNotificationApi.loading = false;
      state.readAllNotificationApi.error = action.error.message;
    },
  },
});
