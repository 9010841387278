import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTermsCondition } from "../../store/reducers/auth";
import PageLayout from "../../Layouts/PageLayouts";
import { useTranslation } from "react-i18next";

const TermsCondition = () => {
  const dispatch = useDispatch();
  const { termsCondition } = useSelector((state) => state.auth);
  const {t} = useTranslation();

  useEffect(() => {
    dispatch(getTermsCondition());
  }, []);

  return (
    <PageLayout
      headerTitle={t("terms_and_conditions")}
      showBackBtn={true}
      showSearchField={false}
    >
      <div className="profile-container">
        <div className="profile-content">
          <div
            className="privacy-data"
            dangerouslySetInnerHTML={{ __html: termsCondition?.body }}
          ></div>
        </div>
      </div>
    </PageLayout>
  );
};

export default TermsCondition;
