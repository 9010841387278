import React, { useRef, useState } from "react";
import { Modal } from "@mui/material";
import CloseICon from "../../assets/images/close-icon.svg";
import ProfilePicture from "../../assets/images/user.svg";
import { useDispatch, useSelector } from "react-redux";

const modalStyle = {
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  position: "absolute",
  backgroundColor: "white",
  boxShadow: 24,
  padding: "32px",
  paddingLeft: 42,
  paddingRight: 24,
  boxSizing: "border-box",
};

export const convertToBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64Image = reader.result;
      resolve(base64Image);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};

const ProfileUpload = ({ onChange, image }) => {
  const [profileModal, setProfileModal] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const image = await convertToBase64(file);
    onChange(file, image);
    setProfileModal(false);
  };

  return (
    <>
      <div className="input-group">
        {image ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <img
              src={image}
              alt=""
              className="profile-picture"
              onClick={() => setProfileModal(true)}
            />
            {/* <div
              onClick={() => setProfileModal(true)}
              className="profile-pic-placeholder"
              style={{ position: "absolute", bottom: 10, cursor: "pointer" }}
            >
              <span>Upload Logo</span>
            </div> */}
          </div>
        ) : (
          <div
            className="profile-picture"
            onClick={() => setProfileModal(true)}
          >
            <div className="profile-pic-placeholder">
              <img src={ProfilePicture} alt="" />
              <span>Upload Logo</span>
            </div>
          </div>
        )}
      </div>
      <input
        name="logo"
        type="file"
        accept="image/*"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      <Modal
        open={profileModal}
        onClose={() => setProfileModal(false)}
        className="main-modal"
      >
        <div style={modalStyle} className="modal-content">
          <div className="modal-body">
            <div className="modal-header">
              <h2>Upload Logo</h2>
              <span onClick={() => setProfileModal(false)}>
                <img src={CloseICon} alt="" />
              </span>
            </div>{" "}
            <div className="modal-body" style={{ paddingRight: 32 }}>
              <div className="input-group button">
                <button
                  type="submit"
                  variant="standard"
                  color="primary"
                  onClick={() => fileInputRef.current.click()}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProfileUpload;
