import { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import CloseICon from "../../assets/images/close-icon.svg";
import React from "react";
import { useSelector } from "react-redux";
import { popupEnum } from "../../utils/constants/order";
import { useTranslation } from "react-i18next";

const ActionModal = ({
  open,
  setOpen,
  title,
  clickHandler,
  loading,
  gateNo = "",
}) => {
  const [commentData, setCommentData] = useState({
    gate: "",
    comment: "",
  });
  const [gate, setGate] = useState(gateNo);
  const { updateOrderStatusApi } = useSelector((state) => state.orders);
  const {t} = useTranslation();

  useEffect(() => {
    setGate(gateNo);
  }, [gateNo]);

  useEffect(() => {
    const comments = { ...commentData };
    comments.gate = gate;
    setCommentData(comments);
  }, [gate, open])

  const modalStyle = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 475,
    position: "absolute",
    backgroundColor: "white",
    boxShadow: 24,
    padding: 16,
    boxSizing: "border-box",
  };

  const handleChange = (event) => {
    const comments = { ...commentData };
    comments.comment = event.target.value;
    setCommentData(comments);
  };

  const handleChangeGate = (event) => {
    setGate(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    clickHandler(commentData);
    const comments = { ...commentData };
    comments.gate = "";
    comments.comment = "";
    setCommentData(comments);
  };

  return (
    <Modal open={open} onClose={setOpen} className="main-modal">
      <div style={modalStyle} className="modal-content">
        <div className="modal-body">
          <div className="modal-header action">
            <span onClick={() => setOpen(false)}>
              <img src={CloseICon} alt="" />
            </span>
          </div>
          <div className="modal-body action">
            <div className="action-reason">
              <h3>{t(popupEnum.label[title])}</h3>
            </div>
            {title === "on_hold/driver_arrived" && (
              <div classname="action-mandatory">
                <span style={{ fontSize: "1.3rem" }}>
                  {t("driver_confirm_description")}
                </span>
              </div>
            )}
            {title === "loading_complete" && (
              <div classname="action-mandatory">
                <span style={{ fontSize: "1.3rem" }}>
                {t("loading_complete_description")}
                </span>
              </div>
            )}
            {title === "loading_delivered" && (
              <div classname="action-mandatory">
                <span style={{ fontSize: "1.3rem" }}>
                {t("delivery_complete_description")}
                </span>
              </div>
            )}
            <form onSubmit={handleSubmit}>
              {(title === "ready" || title === "loading" || title === "ready_off_loading") && (
                <div className="action-textbox">
                  <TextField
                    fullWidth
                    label={t("gate_placeholder")}
                    variant="standard"
                    name="gate_no"
                    onChange={handleChangeGate}
                    value={gate}
                  />
                </div>
              )}
              <div className="action-textbox ">
                <TextField
                  fullWidth
                  label={t("comment_placeholder")}
                  variant="standard"
                  name="comment"
                  onChange={handleChange}
                  sx={{ marginTop: "1rem" }}
                  required
                />
              </div>
              <div className="input-group button">
                <Button
                  className="cursor"
                  type="submit"
                  variant="standard"
                  color="primary"
                >
                  {updateOrderStatusApi?.loading && (
                    <CircularProgress
                      size={18}
                      style={{ marginRight: 10, color: "#fff" }}
                    />
                  )}
                  {t("submit")}
                </Button>
                <Button
                  className="cancel-button cursor"
                  onClick={() => setOpen(false)}
                >
                  {t("cancel")}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ActionModal;
