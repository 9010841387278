import React from "react";
import MUIButton from "@mui/material/Button";
import { CircularProgress, styled } from "@mui/material";

const CustomButton = styled(MUIButton)({
  color: "#FFFFFF",
  backgroundColor: "#7363F8",
  borderRadius: "6.25rem",
});

export const Button = ({
  label = "",
  variant = "contained",
  onClick,
  loading,
  ...props
}) => {
  return (
    <CustomButton
      variant={variant}
      onClick={onClick}
      disableRipple
      disableElevation
      {...props}
    >
      <span className="title-text" title={label}>
        {label}
      </span>
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
    </CustomButton>
  );
};
