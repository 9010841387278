import React, { useEffect, useState } from "react";
import { Container, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { Formik } from "formik";
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
  CircularProgress,
} from "@mui/material";
import { Visibility, VisibilityOff } from "../../components/inputFields";
import Logo from "../../components/logo/logo";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
  loginRequest,
  resetMessages,
  signupRequest,
} from "../../store/reducers/auth";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getAllNotification } from "../../store/reducers/notification";

export const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { api } = useSelector((state) => state.auth);
  const { t, i18n } = useTranslation();

  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    dispatch(resetMessages());
  }, []);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const fetchAllNotification = () => {
    dispatch(getAllNotification())
  };

  const onSignupHandler = (values) => {
    dispatch(signupRequest({ ...values, user_type: "Distributor" }))
      // .then(unwrapResult)
      .then((res) => {
        if (!res.error) {
          toast.success(t("registration_success"));
          dispatch(
            loginRequest({
              username: values?.email,
              password: values?.password,
              user_type: "Distributor",
            })
          ).then((res) => {
            if (!res?.error) {
              console.log("Logged in successfully");
              fetchAllNotification();
              navigation("/profile");
            } else {
              toast.error(res?.error?.message || t("something_went_wrong"));
            }
          });
        } else {
          toast.error(res?.error?.message || t("something_went_wrong"));
        }
      });
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
  }

  return (
    <Container maxWidth="md">
      <div className="logo-container">
        <Logo />
      </div>
      <div className="form-container">
        <Typography
          variant="h2"
          fontWeight="600"
          color="#2B2B2B"
          fontSize="28px"
          textAlign="center"
        >
          {t("sign_up")}
        </Typography>
        <div className="form">
          <Formik
            initialValues={{ email: "", password: "", confirm_password: "" }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .min(
                  8,
                  "Must be at least 8 characters, one letter, one number and one special character"
                )
                .matches(
                  /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
                  "Must be at least 8 characters, one letter, one number and one special character"
                )
                .required()
                .label("Password"),
              confirm_password: Yup.string()
                .min(
                  8,
                  "Must be at least 8 characters, one letter, one number and one special character"
                )
                // .matches(
                //   /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
                //   "Must be at least 8 characters, one letter, one number and one special character"
                // )
                .required("Please re-enter your password")
                .oneOf([Yup.ref("password"), null], "Passwords must match"),
              // .label("Confirm password"),
              email: Yup.string().email().required().label("Email"),
            })}
            onSubmit={onSignupHandler}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="input-group">
                  <TextField
                    fullWidth
                    id="email"
                    label={t("email")}
                    variant="standard"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  <span className="error-message">
                    {touched.email && errors.email}
                  </span>
                </div>

                <div className="input-group">
                  <TextField
                    fullWidth
                    label={t("create_password")}
                    type={showPassword ? "text" : "password"}
                    variant="standard"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <span className="error-message">
                    {touched.password && errors.password}
                  </span>
                </div>
                <div className="input-group">
                  <TextField
                    fullWidth
                    label={t("confirm_password")}
                    type={showPassword1 ? "text" : "password"}
                    variant="standard"
                    name="confirm_password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirm_password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              setShowPassword1(
                                (prevShowPassword) => !prevShowPassword
                              )
                            }
                          >
                            {showPassword1 ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <span className="error-message">
                    {touched.confirm_password && errors.confirm_password}
                  </span>
                </div>
                <div className="input-group new-password-text">
                  <span>{t("change_password_validation")}</span>
                </div>
                
                <div className="input-group">
                <FormControl sx={{ minWidth: 90 }}>
                    <Select
                      value={language}
                      onChange={handleLanguageChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value={"en"}>Eng</MenuItem>
                      <MenuItem value={"es"}>Spanish</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div
                  className="input-group button"
                  style={{ marginBottom: 16 }}
                >
                  <Button type="submit" variant="standard" color="primary">
                    {api.loading && (
                      <CircularProgress
                      size={18}
                      sx={{ color: "#ffff", marginRight: "10px" }}
                      />
                    )}
                    {t("sign_up")}
                  </Button>
                  {/* <span className="error-message">
                    {!api.loading && api.error}
                  </span> */}
                </div>

                <div className="input-group sign-up not-account">
                  <span style={{ color: "#3A3C4A", fontSize: 14 }}>
                    {t("by_using_fast_trax")}{" "}
                    <Link to="/auth/terms-condition">
                      {t("terms_and_conditions")}
                    </Link>{" "}
                    {t("and")}{" "}
                    <Link to="/auth/privacy-policy">{t("privacy_policy")}</Link>
                  </span>
                </div>

                <div className="input-group not-account">
                  <span>
                    {t("already_account")}?{" "}
                    <Link to="/login">{t("login")}</Link>
                  </span>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Container>
  );
};
