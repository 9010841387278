import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddInstructionLabel from "../../../components/addInstructionLabel";
import AddNewInstructionButton from "../../../components/addNewInstructionButton";
import { instructionsUtils } from "../../../utils/constants/order";
import SpecificInstruction from "./SpecificInstruction";
import { useTranslation } from "react-i18next";

export const Instructions = ({
  data,
  setActionModal,
  setPayloadForActionModal,
  currentUserType
}) => {

  const [orderStatus, setOrderStatus] = useState(data?.pickup_status);
  const [orderComments, setOrderComments] = useState(data?.pickup_comments)
  const {t} = useTranslation();

  useEffect(() => {
    if(currentUserType === 0){
      setOrderStatus(data?.pickup_status)
      setOrderComments(data?.pickup_comments)
    }
    else{
      setOrderStatus(data?.delivery_status)
      setOrderComments(data?.delivery_comments)
    }
  }, [data, currentUserType])

  
  return (
    <Grid
      container
      sx={{
        background: "",
        maxHeight: "70vh",
        overflowX: "auto",
        paddingRight: "1rem",
        "&::-webkit-scrollbar": {
          width: 9.65,
          height: 9.65,
        },
        "&::-webkit-scrollbar-track": {
          padding: "2px",
          backgroundColor: "white",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#7B67FDA3",
          width: 5,
          borderRadius: 2,
        },
      }}
    >
      {orderStatus !== "pending" && (
        <Typography
          p={1}
          mb={2}
          sx={{
            fontSize: "1.125rem",
            fontWeight: "600",
            color: "white",
            width: "100%",
            backgroundColor: instructionsUtils.color[orderStatus],
            borderRadius: "100px",
            lineHeight: "18px",
          }}
        >
          {t(instructionsUtils.label[orderStatus])}
        </Typography>
      )}
      {(orderStatus === "approved" ||
        orderStatus === "approved/driver_arrived") && (
        <>
        <SpecificInstruction orderComments={orderComments} instruction = "approved" orderStatus={orderStatus} />
        <SpecificInstruction orderComments={orderComments} instruction = "approved/driver_arrived" orderStatus={orderStatus} />
        <AddNewInstructionButton handleClick = {() => {
              setPayloadForActionModal({
                driver_id: data.driver.id,
                id: data.id,
                order_status: orderStatus,
                label: "approved",
              });
              setActionModal(true);
            }} />
        </>
      )}
      {orderStatus === "declined" && (
        <>
        <SpecificInstruction orderComments={orderComments} instruction = "declined" orderStatus={orderStatus} />
        </>
      )}
      {(orderStatus === "on_hold" ||
        orderStatus === "on_hold/driver_arrived") && (
        <>
          <SpecificInstruction orderComments={orderComments} instruction = "on_hold" orderStatus={orderStatus} />
          <SpecificInstruction orderComments={orderComments} instruction = "on_hold/driver_arrived" orderStatus={orderStatus} />
          <AddNewInstructionButton handleClick = {() => {
              setPayloadForActionModal({
                driver_id: data.driver.id,
                id: data.id,
                order_status: orderStatus,
                label: "on_hold",
              });
              setActionModal(true);
            }} />
        </>
      )}
      {orderStatus === "loading_pending" && (
        <>
        <SpecificInstruction orderComments={orderComments} instruction = "loading_pending" orderStatus={orderStatus} />
        <AddNewInstructionButton handleClick = {() => {
              setPayloadForActionModal({
                driver_id: data.driver.id,
                id: data.id,
                order_status: orderStatus,
                label: "driver_arrived",
              });
              setActionModal(true);
            }} />
          <AddInstructionLabel label = {t("checked_in_instruction")} />
          <SpecificInstruction orderComments={orderComments} instruction = "approved" orderStatus={orderStatus} />
          <SpecificInstruction orderComments={orderComments} instruction = "approved/driver_arrived" orderStatus={orderStatus} />
        </>
      )}
      {orderStatus === "off_loading_pending" && (
        <>
        <SpecificInstruction orderComments={orderComments} instruction = "off_loading_pending" orderStatus={orderStatus} />
        <AddNewInstructionButton handleClick = {() => {
              setPayloadForActionModal({
                driver_id: data.driver.id,
                id: data.id,
                order_status: orderStatus,
                label: "driver_arrived",
              });
              setActionModal(true);
            }} />
          <AddInstructionLabel label = {t("checked_in_instruction")} />
          <SpecificInstruction orderComments={orderComments} instruction = "approved" orderStatus={orderStatus} />
          <SpecificInstruction orderComments={orderComments} instruction = "approved/driver_arrived" orderStatus={orderStatus} />
        </>
      )}
      {orderStatus === "loading_paused" && (
        <>
        <SpecificInstruction orderComments={orderComments} instruction = "loading_paused" orderStatus={orderStatus} />
            <AddNewInstructionButton handleClick = {() => {
              setPayloadForActionModal({
                driver_id: data.driver.id,
                id: data.id,
                order_status: orderStatus,
                label: "loading_paused",
              });
              setActionModal(true);
            }} />
          <AddInstructionLabel label = {t("loading_instruction")} />
          <SpecificInstruction orderComments={orderComments} instruction = "loading" orderStatus={orderStatus} />
          <SpecificInstruction orderComments={orderComments} instruction = "loading/loading_complete" orderStatus={orderStatus} />
          <AddInstructionLabel label = {t("instruction")} />
          <SpecificInstruction orderComments={orderComments} instruction = "loading_pending" orderStatus={orderStatus} />
          <AddInstructionLabel label =  {t("checked_in_instruction")} />
          <SpecificInstruction orderComments={orderComments} instruction = "approved" orderStatus={orderStatus} />
          <SpecificInstruction orderComments={orderComments} instruction = "approved/driver_arrived" orderStatus={orderStatus} />
        </>
      )}
      {orderStatus === "off_loading_paused" && (
        <>
        <SpecificInstruction orderComments={orderComments} instruction = "off_loading_paused" orderStatus={orderStatus} />
            <AddNewInstructionButton handleClick = {() => {
              setPayloadForActionModal({
                driver_id: data.driver.id,
                id: data.id,
                order_status: orderStatus,
                label: "loading_paused",
              });
              setActionModal(true);
            }} />
          <AddInstructionLabel label =  {t("off_loading_instruction")} />
          <SpecificInstruction orderComments={orderComments} instruction = "off_loading" orderStatus={orderStatus} />
          <SpecificInstruction orderComments={orderComments} instruction = "off_loading/delivered" orderStatus={orderStatus} />
          <AddInstructionLabel label =  {t("instruction")} />
          <SpecificInstruction orderComments={orderComments} instruction = "off_loading_pending" orderStatus={orderStatus} />
          <AddInstructionLabel label =  {t("checked_in_instruction")} />
          <SpecificInstruction orderComments={orderComments} instruction = "approved" orderStatus={orderStatus} />
          <SpecificInstruction orderComments={orderComments} instruction = "approved/driver_arrived" orderStatus={orderStatus} />
        </>
      )}
      {(orderStatus === "loading" ||
        orderStatus === "loading/loading_complete") && (
        <>
        <SpecificInstruction orderComments={orderComments} instruction = "loading" orderStatus={orderStatus} />
        <SpecificInstruction orderComments={orderComments} instruction = "loading/loading_complete" orderStatus={orderStatus} />
            <AddNewInstructionButton handleClick = {() => {
              setPayloadForActionModal({
                driver_id: data.driver.id,
                id: data.id,
                order_status: orderStatus,
                gate_no: orderComments?.loading?.[0]?.gate,
                label: "loading",
              });
              setActionModal(true);
            }} />
          <AddInstructionLabel label =  {t("instruction")} />
          <SpecificInstruction orderComments={orderComments} instruction = "loading_pending" orderStatus={orderStatus} />
          <AddInstructionLabel label = {t("checked_in_instruction")} />
          <SpecificInstruction orderComments={orderComments} instruction = "approved" orderStatus={orderStatus} />
          <SpecificInstruction orderComments={orderComments} instruction = "approved/driver_arrived" orderStatus={orderStatus} />
        </>
      )}
      {(orderStatus === "off_loading" ||
        orderStatus === "off_loading/delivered") && (
        <>
        <SpecificInstruction orderComments={orderComments} instruction = "off_loading" orderStatus={orderStatus} />
        <SpecificInstruction orderComments={orderComments} instruction = "off_loading/delivered" orderStatus={orderStatus} />
            <AddNewInstructionButton handleClick = {() => {
              setPayloadForActionModal({
                driver_id: data.driver.id,
                id: data.id,
                order_status: orderStatus,
                gate_no: orderComments?.off_loading?.[0]?.gate,
                label: "loading",
              });
              setActionModal(true);
            }} />
            <AddInstructionLabel label =  {t("instruction")} />
          <SpecificInstruction orderComments={orderComments} instruction = "off_loading_pending" orderStatus={orderStatus} />
          <AddInstructionLabel label =  {t("checked_in_instruction")} />
          <SpecificInstruction orderComments={orderComments} instruction = "approved" orderStatus={orderStatus} />
          <SpecificInstruction orderComments={orderComments} instruction = "approved/driver_arrived" orderStatus={orderStatus} />
        </>
      )}
      {orderStatus === "loading_complete" && (
        <>
        <SpecificInstruction orderComments={orderComments} instruction = "loading_complete" orderStatus={orderStatus} />
        <AddInstructionLabel label =  {t("loading_instruction")} />
          <SpecificInstruction orderComments={orderComments} instruction = "loading" orderStatus={orderStatus} />
          <SpecificInstruction orderComments={orderComments} instruction = "loading/loading_complete" orderStatus={orderStatus} />
          <AddInstructionLabel label =  {t("instruction")} />
          <SpecificInstruction orderComments={orderComments} instruction = "loading_pending" orderStatus={orderStatus} />
          <AddInstructionLabel label =  {t("checked_in_instruction")} />
          <SpecificInstruction orderComments={orderComments} instruction = "approved" orderStatus={orderStatus} />
          <SpecificInstruction orderComments={orderComments} instruction = "approved/driver_arrived" orderStatus={orderStatus} />
        </>
      )}
      {orderStatus === "delivered" && (
        <>
        <SpecificInstruction orderComments={orderComments} instruction = "delivered" orderStatus={orderStatus} />
        <AddInstructionLabel label =  {t("off_loading_instruction")} />
          <SpecificInstruction orderComments={orderComments} instruction = "off_loading" orderStatus={orderStatus} />
          <SpecificInstruction orderComments={orderComments} instruction = "off_loading/delivered" orderStatus={orderStatus} />
          <AddInstructionLabel label =  {t("instruction")} />
          <SpecificInstruction orderComments={orderComments} instruction = "off_loading_pending" orderStatus={orderStatus} />
          <AddInstructionLabel label =  {t("checked_in_instruction")} />
          <SpecificInstruction orderComments={orderComments} instruction = "approved" orderStatus={orderStatus} />
          <SpecificInstruction orderComments={orderComments} instruction = "approved/driver_arrived" orderStatus={orderStatus} />
        </>
      )}
    </Grid>
  );
};

export default Instructions;
