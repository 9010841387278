import { CircularProgress, Divider, Typography } from "@mui/material";
import React from "react";
import award from "../../assets/images/award.svg";
import { Elements, PaymentElement, useStripe } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const SubscriptionCard = ({ data, handleClick, button }) => {

  // const stripe = useStripe();
  // const {} = data
  const {t} = useTranslation();

  return (
    <div className="subscription-card-container">
      {data?.is_subscribed && (
        <div className="subscription-label">
          <Typography color="#FFFFFF">{t("active")}</Typography>
        </div>
      )}
      <div className="heading">
        <Typography
          variant="h2"
          fontWeight="600"
          color="#2B2B2B"
          fontSize="28px"
          textAlign="center"
        >
          {data?.name}
        </Typography>
        <Typography
          color="#7B67FD"
          fontWeight="500"
          fontSize="48px"
          textAlign="center"
        >
          ${data?.price}
        </Typography>
        <Typography
          color="#2B2B2B"
          fontWeight="400"
          fontSize="16px"
          textAlign="center"
        >
          {data?.description}
        </Typography>
      </div>
      <Divider
        orientation="horizontal"
        variant="fullWidth"
        sx={{
          color: "#434343",
          margin: "1rem 0",
          width: "100%",
        }}
      />
      {button !== "" && (
        <div className="subscription-button">
          <button
            className={button === "Cancel"? "cancel-btn": "btn"}
            onClick={() => handleClick( data )}
            disabled={button === "Cancelled"}
          >
            <Typography
              color="#FFFFFF"
              fontWeight="600"
              fontSize="16px"
              textAlign="center"
            >
              {button}
            </Typography>
          </button>
        </div>
      )}
    </div>
  );
};

export default SubscriptionCard;
