import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button } from "@mui/material";
import GoogleMapReact from "google-map-react";
import { useNavigate } from "react-router";
import PageLayout from "../../Layouts/PageLayouts";
import { GOOGLE_API_KEY } from "../../utils/constants/key";
import { useTranslation } from "react-i18next";

const Location = () => {
  const [location, setLocation] = useState({});
  const inputRef = useRef();
  const [maps, setMaps] = useState(null);
  const [address, setAddress] = useState("");
  const navigation = useNavigate();
  const {t} = useTranslation();

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${GOOGLE_API_KEY}`
        )
          .then((res) => res.json())
          .then((res) => {
            setAddress(res.results[0].formatted_address);
          });
      });
    }
  }, []);

  const renderMarkers = (map, maps) => {
    // setMaps(maps);
    const marker = new maps.Marker({
      position: location,
      map,
      draggable: true,
      icon: {
        url: require("../../assets/images/map-marker.png"),
        scaledSize: new maps.Size(23, 28),
        anchor: new maps.Point(25, 25),
      },
    });
    maps.event.addListener(marker, "dragend", (event) => {
      const { latLng } = event;
      const lat = latLng.lat();
      const lng = latLng.lng();
      setLocation({ lat: lat, lng: lng})
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_API_KEY}`
      )
        .then((res) => res.json())
        .then((res) => {
          
          setAddress(res?.results[0]?.formatted_address);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
        ;
    });

    const placeService = new maps.places.Autocomplete(inputRef.current);
    
    placeService.addListener("place_changed", async function () {
      const place = await placeService.getPlace();
      setAddress(place?.formatted_address);
      setLocation({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      marker.setPosition({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    });
  };

  return (
    <PageLayout
      headerTitle={t("location")}
      showBackBtn={true}
      showSearchField={false}
    >
      <div className="location-header" style={{ marginTop: "3rem" }}>
        <form>
          <div className="input-group">
            <input
              fullWidth
              id="del-location"
              label="Delivery Location"
              variant="standard"
              name="del-ocation"
              ref={inputRef}
              className="address-dropdown"
              onChange={(e) => setAddress(e.target.value)}
              value={address}
            />
            <span className="error-message">
            </span>
          </div>

          <div className="input-group button">
            <Button
              type="button"
              variant="standard"
              color="primary"
              onClick={() =>
                navigation("/profile", { state: { address: address, location: location } })
              }
            >
              {t("confirm_location")}
            </Button>
          </div>
        </form>
      </div>
      <div style={{ height: "calc(100vh - 150px)", width: "100%" }}>
        {location.lat && (
          <GoogleMapReact
            bootstrapURLKeys={{
              key: GOOGLE_API_KEY,
              libraries: ["places"],
            }}
            defaultCenter={location}
            center={location}
            defaultZoom={15}
            onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
            yesIWantToUseGoogleMapApiInternals
          />
        )}
      </div>
    </PageLayout>
  );
};

export default Location;
