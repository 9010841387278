import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrderRemarkTitle } from "../../../utils/constants/order";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const SpecificRemark = ({ remark }) => {
  
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const { t, i18n } = useTranslation();

  return (
    <React.Fragment>
      <Grid container >
        <Grid item container flexDirection="column">
          <Grid item xs={8}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "500",
                color: "#989CB8",
                overflowWrap: "anywhere",
                // wordWrap: "break-word",
                lineHeight: "18px",
              }}
            >
              {t(OrderRemarkTitle[remark.status])}
            </Typography>
          </Grid>
          <Grid
            item
            pt={0.5}
            mt={0.5}
            textAlign="right"
            sx={{ background: "" }}
            xs="auto"
          >
            <Typography
              sx={{
                color: "#989CB8",
                fontWeight: "500",
                fontSize: "0.75rem",
                lineHeight: "10px",
              }}
            >
              {dayjs(remark?.time)
                .format("MMM D, YYYY HH:mm")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item pb={2}>
          <Typography
            sx={{
              fontSize: "0.875rem",
              fontWeight: "500",
              color: "#000000",
              lineHeight: "21px",
              overflowWrap: "anywhere",
            }}
          >
            {remark?.remarks}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default SpecificRemark;
