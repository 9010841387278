import { use } from "i18next";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import { toast } from "react-toastify";
import Loader from "../components/Loader/Loader";
import { useTranslation } from "react-i18next";
import { userSubscriptionPlans } from "../store/reducers/subscription";

export const RequireSubscription = ({ children }) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    dispatch(userSubscriptionPlans());
  },[])

  const { data: userSubscriptionData, loading} = useSelector((state) => state.subscription?.userSubscriptionPlansApi);
  if(userSubscriptionData?.is_active_subscription || userSubscriptionData?.is_trial_active ){
    return children;
  }
  if (loading) {
    return <Loader />;
  }

  return <Navigate to="/subscription" />;
};