// import { Box, Grid } from "@mui/material";
import NotificationIcon from "../../assets/images/notifications.svg";
import {
  Alert,
  Box,
  Fade,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import BackIcon from "../../assets/images/back.svg";
import SearchIcon from "@material-ui/icons/Search";
import { useNavigate } from "react-router";
import FormControl from "@mui/material/FormControl";
import React, { useEffect, useState } from "react";
import { Popper } from "@mui/material";
import NativeSelect from "@mui/material/NativeSelect";
import NotificationsPanel from "../NotificationPanel";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { color } from "@mui/system";


const StyledNativeSelect = styled(NativeSelect)({
  background: "",
  padding: "1rem",
  "&&:active:": {
    borderBottom: "none",
  },
  "&&:hover:not(.Mui-disabled, .Mui-error):before, ": {
    borderBottom: "2px solid green",
    // borderBottom: "none",
  },
});

const StyledBackIcon = styled(BackIcon)({
  width: "0.7575rem",
  height: "1.2275rem",
});

const StyledSelect = styled(Select)({
  "&:MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
    background: "red",
  },
});
const Header = (props) => {
  const navigation = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const { newNotification } = useSelector((state) => state.notification);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [language, setLanguage] = useState(i18n.language);

  const handleChange = (event) => {
    setLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
    console.log(i18n.language);
  };

  return (
    <Grid
      pl={2}
      pr={2}
      container
      justifyContent="space-between"
      alignItems="center"
      sx={{
        // width: "100vw",
        background: "",
        height: "5rem",
        borderBottom: "1px solid #C2C1BD91",
      }}
    >
      <Grid item xs={4} sx={{ backgroundColor: "" }}>
        <Grid container alignItems="center" gap={2}>
          <Grid item>
            {props.showBackBtn && (
              <img
                src={BackIcon}
                style={{
                  display: "block",
                  width: "0.7575rem",
                  height: "100%",
                }}
                alt=""
                onClick={() => navigation(-1)}
              />
            )}
          </Grid>
          <Grid item>
            <Typography
              sx={{
                color: "black",
                fontWeight: 600,
                fontSize: "1.5rem",
                lineHeight: "41px",
              }}
            >
              {props.headerTitle}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={8}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ background: "", height: "100%" }}
        >
          <Grid item xs={8}>
            {props.showSearchField && (
              <TextField
                placeholder={t("header_search_placeholder")}
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "6.25rem",
                    width: "26rem",
                    height: "2.5rem",
                  },
                }}
                onChange={props.handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <Grid container alignItems="center" justifyContent="end">
              <Grid item xs={6}>
                <FormControl sx={{minWidth: 90}}>
                  <Select
                    value={language}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value={'en'}>Eng</MenuItem>
                    <MenuItem value={'es'}>Spanish</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {props.showNotification && (
                <Grid item xs={6}>
                  <Grid container sx={{ position: "relative" }}>
                    <img
                      src={NotificationIcon}
                      style={{
                        width: "2rem",
                        height: "2rem",
                        cursor: "pointer",
                      }}
                      alt="Bell icon"
                      onClick={handleClick}
                    />
                    {newNotification && (
                      <Box
                        sx={{
                          minWidth: "20px",
                          height: "20px",
                          background: "red",
                          borderRadius: "50%",
                          position: "absolute",
                          left: "18px",
                          bottom: "20px",
                          padding: "2px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            marginLeft: "2px",
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "white",
                          }}
                        >
                          {newNotification > 99 ? "99+" : newNotification}
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                  <Popper open={open} anchorEl={anchorEl} transition>
                    {({ TransitionProps }) => (
                      <ClickAwayListener onClickAway={() => setOpen(false)}>
                        <Fade {...TransitionProps} timeout={350}>
                          <Box
                            sx={{
                              width: "20.4375rem",
                              background: "#FFFFFF",
                              marginTop: "1.8rem",
                              borderRadius: "10px",
                              boxShadow: "0px 8px 8px 0px #0000003D",
                            }}
                          >
                            <NotificationsPanel />
                          </Box>
                        </Fade>
                      </ClickAwayListener>
                    )}
                  </Popper>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Header;
