import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import { FIREBASE_API_KEY } from "./utils/constants/key";

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: "fast-trax-fdf63.firebaseapp.com",
  projectId: "fast-trax-fdf63",
  storageBucket: "fast-trax-fdf63.appspot.com",
  messagingSenderId: "141769427854",
  appId: "1:141769427854:web:401b28140d1bffcb9972df",
  measurementId: "G-FTP6H1DZ7Y"
  };

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);