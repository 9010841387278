import httpClient from "../utils/httpClient";

export function apiGetSubscriptionPlans() {
  return httpClient.get(`/subscriptions/get_subscription_plans/`);
}

export function apiUserSubscriptionPlans() {
  return httpClient.get(`/subscriptions/user_sub_details/`);
}

export const apiBuySubscriptionPlan = (payload) => {
  return httpClient.post(`/subscriptions/buy_subscription_plan/`, payload);
}

export const apiCancelSubscriptionPlan = () => {
  return httpClient.post(`/subscriptions/cancel_subscription_plan/`);
}

export const apiUserTrialSubscriptionPlan = () => {
  return httpClient.patch(`/api/v1/start_trial/`);
}