import { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router";
import { orderStatusType } from "../../utils/constants/order";
import { useTranslation } from "react-i18next";

const ActionButton = ({
  row,
  setActionModal,
  setPayloadForActionModal,
  view,
  currentUserType,
}) => {
  const navigate = useNavigate();

  const StyledButton = styled(Button)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "6.25rem",
    padding: "0.5rem 1rem",
    marginRight: "4px",
    disableElevation: true,
    width: "auto",
    textTransform: "capitalize",
  });
  const [orderStatus, setOrderStatus] = useState(row?.pickup_status)
  const [orderComments, setOrderComments] = useState(row?.pickup_comments)
  const {t} = useTranslation();

  useEffect(() => {
    if(currentUserType === 0){
      setOrderStatus(row?.pickup_status)
      setOrderComments(row?.pickup_comments)
    }
    else{
      setOrderStatus(row?.delivery_status)
      setOrderComments(row?.delivery_comments)
    }
  }, [row, currentUserType])

  return (
    <>
      {view && (
        <Grid item>
          <StyledButton
            onClick={() =>
              navigate("/ViewDetails", {
                state: { data: row?.id, currentUserType: currentUserType },
              })
            }
            variant="contained"
            sx={{
              backgroundColor: "#7363F8",
            }}
          >
            {t("view")}
          </StyledButton>
        </Grid>
      )}
      {(orderStatus === "pending" ||
        orderStatus === "on_hold") && (
        <Grid item>
          <StyledButton
            variant="contained"
            sx={{ backgroundColor: "#2ECC71" }}
            onClick={() => {
              setPayloadForActionModal({
                driver_id: row.driver.id,
                id: row.id,
                order_status: "approved",
                label: "approved",
              });
              setActionModal(true);
            }}
          >
            {t("approve")}
          </StyledButton>
        </Grid>
      )}
      {orderStatus === "pending" && (
        <Grid item>
          <StyledButton
            variant="contained"
            sx={{ backgroundColor: "#E4BF44" }}
            onClick={() => {
              setPayloadForActionModal({
                driver_id: row.driver.id,
                id: row.id,
                order_status: "on_hold",
                label: "on_hold",
              });
              setActionModal(true);
            }}
          >
            {t("hold")}
          </StyledButton>
        </Grid>
      )}
      {orderStatus === "on_hold/driver_arrived" && (
        <>
          <Grid item>
            <StyledButton
              variant="contained"
              sx={{ backgroundColor: "#2ECC71" }}
              onClick={() => {
                setPayloadForActionModal({
                  driver_id: row.driver.id,
                  id: row.id,
                  order_status: currentUserType === 0 ?"loading_pending" : "off_loading_pending",
                  label: "on_hold/driver_arrived",
                });
                setActionModal(true);
              }}
            >
              {t("confirm_driver_arrival")}
            </StyledButton>
          </Grid>
          <Grid item>
            <StyledButton
              variant="contained"
              sx={{ backgroundColor: "#F47665" }}
              onClick={() => {
                setPayloadForActionModal({
                  driver_id: row.driver.id,
                  id: row.id,
                  order_status: "on_hold",
                  label: "cancel",
                  is_message: true,
                });
                setActionModal(true);
              }}
            >
              {t("cancel")}
            </StyledButton>
          </Grid>
        </>
      )}
      {orderStatus === "approved" && (
        <Grid item>
          <StyledButton
            variant="contained"
            sx={{ backgroundColor: "#7363F8" }}
            onClick={() => {
              setPayloadForActionModal({
                driver_id: row.driver.id,
                id: row.id,
                order_status: currentUserType === 0 ? "loading_pending" : "off_loading_pending",
                label: "driver_arrived",
              });
              setActionModal(true);
            }}
          >
            {t("mark_driver_arrived")}
          </StyledButton>
        </Grid>
      )}
      {orderStatus === "approved/driver_arrived" && (
        <>
          <Grid item>
            <StyledButton
              variant="contained"
              sx={{ backgroundColor: "#2ECC71" }}
              onClick={() => {
                setPayloadForActionModal({
                  driver_id: row.driver.id,
                  id: row.id,
                  order_status: currentUserType === 0 ? "loading_pending" : "off_loading_pending",
                  label: "approved/driver_arrived",
                });
                setActionModal(true);
              }}
            >
              {t("confirm_driver_arrival")}
            </StyledButton>
          </Grid>
          <Grid item>
            <StyledButton
              variant="contained"
              sx={{ backgroundColor: "#F47665" }}
              onClick={() => {
                setPayloadForActionModal({
                  driver_id: row.driver.id,
                  id: row.id,
                  order_status: "approved",
                  label: "cancel",
                  is_message: true,
                });
                setActionModal(true);
              }}
            >
              {t("cancel")}
            </StyledButton>
          </Grid>
        </>
      )}
      {(orderStatus === "loading_pending" ||
        orderStatus === "loading_paused") && (
        <Grid item>
          <StyledButton
            variant="contained"
            sx={{ backgroundColor: "#2ECC71" }}
            onClick={() => {
              setPayloadForActionModal({
                driver_id: row.driver.id,
                id: row.id,
                order_status: "loading",
                label: "ready",
                gate_no: orderComments?.loading?.[0]?.gate,
              });
              setActionModal(true);
            }}
          >
            {t("ready_for_loading")}
          </StyledButton>
        </Grid>
      )}
      {(orderStatus === "off_loading_pending" ||
        orderStatus === "off_loading_paused") && (
        <Grid item>
          <StyledButton
            variant="contained"
            sx={{ backgroundColor: "#2ECC71" }}
            onClick={() => {
              setPayloadForActionModal({
                driver_id: row.driver.id,
                id: row.id,
                order_status: "off_loading",
                label: "ready_off_loading",
                gate_no: orderComments?.off_loading?.[0]?.gate,
              });
              setActionModal(true);
            }}
          >
            {t("ready_off_loading")}
          </StyledButton>
        </Grid>
      )}
      {orderStatus === "loading" && (
        <>
          <Grid item>
            <StyledButton
              variant="contained"
              sx={{ backgroundColor: "#E4BF44" }}
              onClick={() => {
                setPayloadForActionModal({
                  driver_id: row.driver.id,
                  id: row.id,
                  order_status: "loading_paused",
                  label: "loading_paused",
                });
                setActionModal(true);
              }}
            >
              {t("halt_loading")}
            </StyledButton>
          </Grid>
          <Grid item>
            <StyledButton
              variant="contained"
              sx={{ backgroundColor: "#7363F8" }}
              onClick={() => {
                setPayloadForActionModal({
                  driver_id: row.driver.id,
                  id: row.id,
                  order_status: "loading_complete",
                  label: "loading_complete",
                });
                setActionModal(true);
              }}
            >
              {t("mark_loading_complete")}
            </StyledButton>
          </Grid>
        </>
      )}
      {orderStatus === "off_loading" && (
        <>
          <Grid item>
            <StyledButton
              variant="contained"
              sx={{ backgroundColor: "#E4BF44" }}
              onClick={() => {
                setPayloadForActionModal({
                  driver_id: row.driver.id,
                  id: row.id,
                  order_status: "off_loading_paused",
                  label: "loading_paused",
                });
                setActionModal(true);
              }}
            >
              {t("halt_off_loading")}
            </StyledButton>
          </Grid>
          <Grid item>
            <StyledButton
              variant="contained"
              sx={{ backgroundColor: "#7363F8" }}
              onClick={() => {
                setPayloadForActionModal({
                  driver_id: row.driver.id,
                  id: row.id,
                  order_status: "delivered",
                  label: "loading_delivered",
                });
                setActionModal(true);
              }}
            >
              {t("mark_delivered")}
            </StyledButton>
          </Grid>
        </>
      )}
      {orderStatus === "loading/loading_complete" && (
        <>
          <Grid item>
            <StyledButton
              variant="contained"
              sx={{ backgroundColor: "#2ECC71" }}
              onClick={() => {
                setPayloadForActionModal({
                  driver_id: row.driver.id,
                  id: row.id,
                  order_status: "loading_complete",
                  label: "loading_complete",
                });
                setActionModal(true);
              }}
            >
              {t("confirm_loading_complete")}
            </StyledButton>
          </Grid>
          <Grid item>
            <StyledButton
              variant="contained"
              sx={{ backgroundColor: "#F47665" }}
              onClick={() => {
                setPayloadForActionModal({
                  driver_id: row.driver.id,
                  id: row.id,
                  order_status: "loading",
                  label: "cancel",
                  is_message: true,
                });
                setActionModal(true);
              }}
            >
              {t("cancel")}
            </StyledButton>
          </Grid>
        </>
      )}
      {orderStatus === "off_loading/delivered" && (
        <>
          <Grid item>
            <StyledButton
              variant="contained"
              sx={{ backgroundColor: "#2ECC71" }}
              onClick={() => {
                setPayloadForActionModal({
                  driver_id: row.driver.id,
                  id: row.id,
                  order_status: "delivered",
                  label: "loading_delivered",
                });
                setActionModal(true);
              }}
            >
              {t("confirm_delivery")}
            </StyledButton>
          </Grid>
          <Grid item>
            <StyledButton
              variant="contained"
              sx={{ backgroundColor: "#F47665" }}
              onClick={() => {
                setPayloadForActionModal({
                  driver_id: row.driver.id,
                  id: row.id,
                  order_status: "off_loading",
                  label: "cancel",
                  is_message: true,
                });
                setActionModal(true);
              }}
            >
              {t("cancel")}
            </StyledButton>
          </Grid>
        </>
      )}
      {(orderStatus === "pending" ||
        orderStatus === "approved" ||
        orderStatus === "on_hold" ||
        orderStatus === "loading_pending" ||
        orderStatus === "loading" ||
        orderStatus === "loading_paused" ||
        orderStatus === "off_loading_pending" ||
        orderStatus === "off_loading_paused" ||
        orderStatus === "off_loading"
        ) && (
        <Grid item>
          <StyledButton
            variant="contained"
            sx={{ backgroundColor: "#F47665" }}
            onClick={() => {
              setPayloadForActionModal({
                driver_id: row.driver.id,
                id: row.id,
                order_status: "declined",
                label: "declined",
              });
              setActionModal(true);
            }}
          >
            {t("decline")}
          </StyledButton>
        </Grid>
      )}
    </>
  );
};

export default ActionButton;
