import React, { useEffect, useState } from "react";
import { CircularProgress, Modal } from "@mui/material";
import CloseICon from "../../assets/images/close-icon.svg";
import Delete from "../../assets/images/delete.svg";
import Next from "../../assets/images/next.svg";
import Warning from "../../assets/images/warning.svg";
import { useDispatch, useSelector } from "react-redux";
import { deleteAccount, logOut } from "../../store/reducers/auth";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const modalStyle = {
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 475,
  position: "absolute",
  backgroundColor: "white",
  boxShadow: 24,
  padding: 16,
  boxSizing: "border-box",
};

const DeleteModal = () => {
  const [profileModal, setProfileModal] = useState(false);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { user, deleteAccountApi } = useSelector((state) => state.auth);
  const { t } = useTranslation();

  const deleteAccountHandler = () => {
    dispatch(deleteAccount())
      .then(unwrapResult)
      .then(() => [
        localStorage.removeItem("@token"),
        localStorage.removeItem("@user"),
        dispatch(logOut()),
      ]);
  };

  return (
    <>
      <li onClick={() => setProfileModal(true)}>
        <span>
          <img src={Delete} alt="" /> {t("delete_account")}
        </span>
        <span>
          <img src={Next} alt="" />
        </span>
      </li>

      <Modal
        open={profileModal}
        onClose={() => setProfileModal(false)}
        className="main-modal"
      >
        <div style={modalStyle} className="modal-content">
          <div className="modal-body">
            <div className="modal-header location">
              <span onClick={() => setProfileModal(false)}>
                <img src={CloseICon} alt="" />
              </span>
            </div>
            <div className="modal-body location">
              <div className="location-marker">
                <img src={Warning} alt="" />
                <h3>{t("delete_account_description")}</h3>
              </div>
              <div className="input-group button delete-button">
                <button
                  type="button"
                  variant="standard"
                  color="primary"
                  onClick={deleteAccountHandler}
                >
                  {deleteAccountApi.loading && (
                    <CircularProgress
                      size={18}
                      sx={{ color: "#ffff", marginRight: "10px" }}
                    />
                  )}{" "}
                  {t("delete")}
                </button>
                <button
                  className="cancel-button"
                  onClick={() => setProfileModal(false)}
                >
                  {t("cancel")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteModal;
