import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setAuthToken } from "../../utils/httpClient";
import {
  apiSignupRequest,
  apiLoginRequest,
  apiAuthUserRequest,
  apiPasswordResetRequest,
  apiUploadLogoRequest,
  apiPostDistributorRequest,
  apiGetDistributorRequest,
  apiUpdateDistributorRequest,
  apiDeleteAccountRequest,
  apiGetPrivacyPolicyRequest,
  apiGetTermsConditionRequest,
  apiChangePasswordRequest,
  apiVerifyOtpRequest,
  apiNewPasswordRequest,
  apiSendFeedback,
  apiLogout,
} from "../../apis/auth";
import { getServerError } from "../../utils/utils";

export const signupRequest = createAsyncThunk(
  "login/signupRequest",
  async (payload) => {
    try {
      const response = await apiSignupRequest(payload);
      return response.data;
    } catch (err) {
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      throw new Error(errorMsg);
    }
  }
);

export const loginRequest = createAsyncThunk(
  "login/loginRequest",
  async (payload) => {
    try {
      let user = {};
      const response = await apiLoginRequest(payload);
      localStorage.setItem("@token", JSON.stringify(response.data.token));
      user = { ...response.data.user };
      if (response.data.user?.profile_data) {
        localStorage.setItem(
          "@user",
          JSON.stringify(response.data.user?.profile_data)
        );
        user = {
          ...user,
          ...response.data.user?.profile_data,
          profile_data_id: response.data.user?.profile_data.id,
        };
      }
      setAuthToken(response.data.token);
      return { token: response.data.token, user };
    } catch (err) {
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      throw new Error(errorMsg);
    }
  }
);

export const resetPasswordRequest = createAsyncThunk(
  "login/resetPasswordRequest",
  async (payload) => {
    try {
      await apiPasswordResetRequest(payload);
    } catch (err) {
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      throw new Error(errorMsg);
    }
  }
);

export const getAuthUser = createAsyncThunk("login/getAuthUser", async () => {
  let distributionResponse;
  let token = localStorage.getItem("@token");
  setAuthToken(JSON.parse(token));
  const response = await apiAuthUserRequest(JSON.parse(token));
  const user = JSON.parse(localStorage.getItem("@user"));
  if (user) {
    distributionResponse = await apiGetDistributorRequest(user.id);
  }
  if (distributionResponse) {
    return {
      ...response.data,
      ...distributionResponse.data,
      profile_data_id: distributionResponse.data.id,
    };
  }
  return response.data;
});

export const postDistributor = createAsyncThunk(
  "login/getDistributor",
  async (payload) => {
    try {
      const response = await apiPostDistributorRequest(payload);
      localStorage.setItem("@user", JSON.stringify(response.data));
      return response.data;
    } catch (err) {
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      throw new Error(errorMsg);
    }
  }
);

export const updateDistributor = createAsyncThunk(
  "login/getDistributor",
  async (payload) => {
    try {
      const response = await apiUpdateDistributorRequest(payload);
      return response.data;
    } catch (err) {
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      throw new Error(errorMsg);
    }
  }
);

export const uploadLogo = createAsyncThunk(
  "login/uploadLogo",
  async (payload) => {
    const response = await apiUploadLogoRequest(payload);
    return {
      logo: response.data.id,
      imageUrl: payload.imageUrl,
    };
  }
);

export const deleteAccount = createAsyncThunk(
  "login/deleteAccount",
  async () => {
    await apiDeleteAccountRequest();
  }
);

export const getLogo = createAsyncThunk("login/getLogo", async () => {});

export const getPrivacyPolicy = createAsyncThunk(
  "login/privacyPolicyRequest",
  async () => {
    try {
      const response = await apiGetPrivacyPolicyRequest();
      return response.data;
    } catch (err) {
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      throw new Error(errorMsg);
    }
  }
);

export const getTermsCondition = createAsyncThunk(
  "login/getTermsCondition",
  async () => {
    try {
      const response = await apiGetTermsConditionRequest();
      return response.data;
    } catch (err) {
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      throw new Error(errorMsg);
    }
  }
);

export const changePassword = createAsyncThunk(
  "login/changePassword",
  async (payload) => {
    try {
      const response = await apiChangePasswordRequest(payload);
      return response.data;
    } catch (err) {
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      throw new Error(errorMsg);
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "login/verifyOtp",
  async (payload) => {
    try {
      const response = await apiVerifyOtpRequest(payload);
      return response.data;
    } catch (err) {
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      throw new Error(errorMsg);
    }
  }
);

export const newPasswordRequest = createAsyncThunk(
  "login/newPasswordRequest",
  async (payload) => {
    try {
      const response = await apiNewPasswordRequest(payload);
      return response.data;
    } catch (err) {
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      throw new Error(errorMsg);
    }
  }
);

export const sendFeedback = createAsyncThunk(
  "login/sendFeedback",
  async (payload) => {
    try {
      const response = await apiSendFeedback(payload);
      return response.data;
    } catch (err) {
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      throw new Error(errorMsg);
    }
  }
);

export const logoutSession = createAsyncThunk(
  "login/sendFeedback",
  async () => {
    try {
      const response = await apiLogout();
      return response.data;
    } catch (err) {
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      throw new Error(errorMsg);
    }
  }
);

const initialAuthState = {
  api: { loading: false, error: null },
  uploadApi: { loading: false, error: null },
  postDistributorApi: { loading: false, error: null },
  error: "",
  user: {},
  isLoggedIn: false,
  initialLoading: true,
  isResetPasswordSuccess: false,
  isDeleteAccountSuccess: false,
  deleteAccountApi: { loading: false, error: null },
  privacyPolicy: {},
  termsCondition: {},
  changePasswordApi: { loading: false, error: null },
  verifyOtpApi: { loading: false, error: null },
  newPasswordApi: { loading: false, error: null },
  apiNewPasswordApi: { loading: false, error: null },
  profile: {},
  sendFeedbackApi: { loading: false, error: null },
  logoutSessionApi: { loading: false, error: null },
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    resetMessages: (state) => {
      state.api.error = null;
      state.verifyOtpApi.error = null;
      state.newPasswordApi.error = null;
    },
    resetChangePasswordMessages: (state) => {
      state.changePasswordApi.error = null;
    },
    resetIsResetPasswordSuccess: (state) => {
      state.isResetPasswordSuccess = false;
    },
    logOut: (state) => {
      state.isLoggedIn = false;
      state.user = {};
      state.profile = {};
    },
    resetInitializeLoading: (state) => {
      state.initialLoading = false;
    },
    setProfileDate: (state, action) => {
      state.profile = {
        ...state.user,
        ...action.payload,
      };
    },
    
  },
  extraReducers: {
    [signupRequest.pending]: (state) => {
      state.api.loading = true;
      state.api.error = null;
      state.token = null;
    },
    [signupRequest.fulfilled]: (state, action) => {
      state.user = action.payload.user;
      state.api.loading = false;
    },
    [signupRequest.rejected]: (state, action) => {
      state.api.error = action.error?.message || "";
      state.api.loading = false;
    },
    [loginRequest.pending]: (state) => {
      state.api.loading = true;
      state.api.error = null;
      state.isLoggedIn = false;
    },
    [loginRequest.fulfilled]: (state, action) => {
      state.user = action.payload.user;
      state.profile = action.payload.user;
      state.api.loading = false;
      state.isLoggedIn = true;
    },
    [loginRequest.rejected]: (state, action) => {
      state.api.error = action.error?.message || "";
      state.api.loading = false;
      state.isLoggedIn = false;
    },

    [getAuthUser.pending]: (state) => {
      state.api.loading = true;
      state.api.error = null;
      state.isLoggedIn = false;
      state.initialLoading = false;
    },
    [getAuthUser.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.profile = action.payload;
      state.api.loading = false;
      state.isLoggedIn = true;
    },
    [getAuthUser.rejected]: (state, action) => {
      state.api.loading = false;
      state.isLoggedIn = false;
    },
    [resetPasswordRequest.pending]: (state) => {
      state.api.loading = true;
      state.api.error = null;
      state.isResetPasswordSuccess = false;
    },
    [resetPasswordRequest.fulfilled]: (state) => {
      state.api.loading = false;
      state.isResetPasswordSuccess = true;
    },
    [resetPasswordRequest.rejected]: (state, action) => {
      state.api.loading = false;
      state.api.error = action.payload;
      state.isResetPasswordSuccess = false;
    },

    [uploadLogo.pending]: (state) => {
      state.uploadApi.loading = true;
      state.uploadApi.error = null;
    },
    [uploadLogo.fulfilled]: (state, action) => {
      state.uploadApi.loading = false;
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },
    [uploadLogo.rejected]: (state, action) => {
      state.uploadApi.loading = false;
      state.uploadApi.error = action.payload;
    },
    [postDistributor.pending]: (state) => {
      state.postDistributorApi.loading = true;
      state.postDistributorApi.error = null;
    },
    [postDistributor.fulfilled]: (state, action) => {
      state.postDistributorApi.loading = false;
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },
    [postDistributor.rejected]: (state, action) => {
      state.postDistributorApi.loading = false;
      state.postDistributorApi.error = action.error.message;
    },
    [updateDistributor.pending]: (state) => {
      state.postDistributorApi.loading = true;
      state.postDistributorApi.error = null;
    },
    [updateDistributor.fulfilled]: (state, action) => {
      state.postDistributorApi.loading = false;
      state.user = {
        ...state.user,
        ...action.payload,
        profile_data_id: action.payload.id,
      };
    },
    [updateDistributor.rejected]: (state, action) => {
      state.postDistributorApi.loading = false;
      state.postDistributorApi.error = action.error.message;
    },
    [deleteAccount.pending]: (state) => {
      state.deleteAccountApi.loading = true;
      state.deleteAccountApi.error = null;
    },
    [deleteAccount.fulfilled]: (state, action) => {
      state.deleteAccountApi.loading = false;
      state.isDeleteAccountSuccess = true;
      state.isLoggedIn = false;
      state.user = {};
    },
    [deleteAccount.rejected]: (state, action) => {
      state.deleteAccountApi.loading = false;
      state.deleteAccountApi.error = action.error.message;
    },
    [getPrivacyPolicy.fulfilled]: (state, action) => {
      state.privacyPolicy = action.payload?.[0];
    },
    [getTermsCondition.fulfilled]: (state, action) => {
      state.termsCondition = action.payload?.[0];
    },
    [changePassword.pending]: (state) => {
      state.changePasswordApi.loading = true;
      state.changePasswordApi.error = null;
    },
    [changePassword.fulfilled]: (state, action) => {
      state.changePasswordApi.loading = false;
    },
    [changePassword.rejected]: (state, action) => {
      state.changePasswordApi.loading = false;
      state.changePasswordApi.error = action.error.message;
    },
    [verifyOtp.pending]: (state) => {
      state.verifyOtpApi.loading = true;
      state.verifyOtpApi.error = null;
    },
    [verifyOtp.fulfilled]: (state, action) => {
      state.verifyOtpApi.loading = false;
    },
    [verifyOtp.rejected]: (state, action) => {
      state.verifyOtpApi.loading = false;
      state.verifyOtpApi.error = action.error.message;
    },
    [newPasswordRequest.pending]: (state) => {
      state.newPasswordApi.loading = true;
      state.newPasswordApi.error = null;
    },
    [newPasswordRequest.fulfilled]: (state, action) => {
      state.newPasswordApi.loading = false;
    },
    [newPasswordRequest.rejected]: (state, action) => {
      state.newPasswordApi.loading = false;
      state.newPasswordApi.error = action.error.message;
    },
    [sendFeedback.pending]: (state) => {
      state.sendFeedbackApi.loading = true;
      state.sendFeedbackApi.error = null;
    },
    [sendFeedback.fulfilled]: (state, action) => {
      state.sendFeedbackApi.loading = false;
    },
    [sendFeedback.rejected]: (state, action) => {
      state.sendFeedbackApi.loading = false;
      state.sendFeedbackApi.error = action.error.message;
    },
    [logoutSession.pending]: (state) => {
      state.logoutSessionApi.loading = true;
      state.logoutSessionApi.error = null;
    },
    [logoutSession.fulfilled]: (state, action) => {
      state.logoutSessionApi.loading = false;
    },
    [logoutSession.rejected]: (state, action) => {
      state.logoutSessionApi.loading = false;
      state.logoutSessionApi.error = action.error.message;
    },
  },
});

export const {
  resetIsResetPasswordSuccess,
  logOut,
  resetInitializeLoading,
  resetChangePasswordMessages,
  resetMessages,
  setProfileDate,
} = authSlice.actions;
