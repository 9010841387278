import axios from "axios";

// export const SERVICE_URL = "https://orange-sea-37974-staging.botics.co";
export const SERVICE_URL = window.location.origin.includes("localhost")
  ? "https://orange-sea-37974-staging.botics.co"
  : window.location.origin;

const httpClient = axios.create({
  baseURL: SERVICE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

httpClient.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    let token = localStorage.getItem("@token");
    if (token) {
      config.headers.Authorization = `Token ${JSON.parse(token)}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

httpClient.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    if (error.response.status === 401) {
      window.location.href = "/login";
      localStorage.clear();
    }
    return Promise.reject(error);
  }
);

export const setAuthToken = (token) => {
  httpClient.defaults.headers.common["Authorization"] = `Token ${token}`;
  delete axios.defaults.headers.common["Cookie"];
};

export default httpClient;
