import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import Logo from "../../assets/images/timer.svg";

const logo = () => {
  return (
    <Grid
      container
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <img src={Logo} alt="Logo" />
      </Grid>
      <Grid item>
        <Typography
          variant="h2"
          color="#7B67FD"
          fontWeight="700"
          fontSize="32px"
        >
          Fast Trax
        </Typography>
      </Grid>
    </Grid>
  );
};

export default logo;
