import httpClient from "../utils/httpClient";


export function apiFirebaseToken(payload) {
  return httpClient.post(`/notification/add-fcm-device/`,payload);
}

export function apiGetAllNotification(config) {
  return httpClient.get(`/notification/fcm-notification/`, config);
}

export function apiUpdateNotification(payload) {
  return httpClient.patch(
    `/notification/fcm-notification/${payload.id}/`,
    payload.payload
  );
}

export function apiReadAllNotification() {
  return httpClient.get(`/notification/fcm-notification/mark-all-read/`);
}