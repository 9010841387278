import React from 'react';
import { Modal } from '@mui/material';
import CloseICon from '../../assets/images/close-icon.svg'
import Pin from '../../assets/images/pin.svg'
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const LocationModal = ({ open, onClose }) => {

    const {t} = useTranslation();
    const navigate = useNavigate()
    const modalStyle = {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 475,
        position: 'absolute',
        backgroundColor: 'white',
        boxShadow: 24,
        padding: 16,
        boxSizing: 'border-box'
    };

    return (
        <>
            <Modal open={open} onClose={onClose} className='main-modal'>
                <div style={modalStyle} className='modal-content'>
                    <div className="modal-body">
                        <div className="modal-header location">
                            <span onClick={onClose}><img src={CloseICon} alt="" /></span>
                        </div>
                        <div className="modal-body location">
                            <div className="location-marker">
                                <img src={Pin} alt="" />
                                <h3>{t("location_permission")}</h3>
                            </div>
                            <div className="input-group button">
                                <button type='submit' variant="standard" color="primary" onClick={() => navigate("/location")}>
                                    {t("yes")}
                                </button>
                                <button className='cancel-button' onClick={onClose}>
                                    {t("cancel")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default LocationModal