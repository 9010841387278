import httpClient from "../utils/httpClient";

export function apiGetAllOrders(config) {
  return httpClient.get(`/orders/order/`, config);
}

export function apiGetOrder(id) {
  return httpClient.get(`/orders/order/${id}/`);
}

export function apiUpdateOrderStatus(payload) {
  return httpClient.patch(
    `/orders/order/${payload.id}/update_status/`,
    payload.payload
  );
}

export function apiGetDriverLocation(id) {
  return httpClient.get(`/api/v1/driver_location/${id}/`);
}
