import InstructionCard from "./InstructionCard"

const SpecificInstruction = ({orderComments, instruction, orderStatus}) => {

  return (
    orderComments?.[instruction] && orderComments?.[instruction].map((comment, index) => (
        <InstructionCard key = {index}
          status={orderStatus}
          pickupComments={comment}
        />
      ))
  )
}

export default SpecificInstruction