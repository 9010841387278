import { Modal, Typography } from "@mui/material";
import React from "react";
import CloseICon from "../../assets/images/close-icon.svg";
import { useTranslation } from "react-i18next";

const CancelSubscriptionModal = ({ open, setOpen, clickHandler }) => {
  const modalStyle = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 475,
    position: "absolute",
    backgroundColor: "white",
    boxShadow: 24,
    padding: 16,
    boxSizing: "border-box",
  };
  const {t} = useTranslation();

  return (
    <Modal open={open} onClose={setOpen} className="main-modal">
      <div style={modalStyle} className="modal-content">
        <div className="modal-body">
          <div className="modal-header action">
            <span onClick={() => setOpen(false)}>
              <img src={CloseICon} alt="" />
            </span>
          </div>
          <div className="modal-body subscription">
            <div className="action-reason">
              <h3>{t("cancel_subscription")}</h3>
            </div>
            <div classname="modal-body subscription">
              <span style={{ fontSize: "1.3rem" }}>
                {t("cancel_subscription_description")}
              </span>
            </div>
            <div className="modal-body upgrade-button">
              <button
                className="cancel-btn"
                onClick={() => {
                  clickHandler();
                }}
              >
                <Typography
                  color="#FFFFFF"
                  fontWeight="600"
                  fontSize="16px"
                  textAlign="center"
                >
                  {t("yes")}
                </Typography>
              </button>
              <button
                className="btn"
                onClick={() => setOpen(false)}
              >
                <Typography
                  color="#FFFFFF"
                  fontWeight="600"
                  fontSize="16px"
                  textAlign="center"
                >
                  {t("no")}
                </Typography>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CancelSubscriptionModal;
