import { Alert, Grid, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getAllNotification, readAllNotification } from "../../store/reducers/notification";
import Notifications from "./Notifications";

const NotificationsPanel = () => {
  const [topNotification, setTopNotification] = useState([]);
  const { data: AllNotification, loading } = useSelector(
    (state) => state.notification.getAllNotificationApi
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getAllNotification());
  }, []);

  useEffect(() => {
    let notification = [];
    let count = 0;
    for (let i = 0; i < 6; i++) {
      notification.push(AllNotification?.results?.[i]);
      if (AllNotification?.results?.[i]?.is_read === true) {
        count++;
      }
    }
    if (AllNotification?.results?.length < 7) {
      setTopNotification(AllNotification?.results);
    } else {
      setTopNotification(notification);
    }
  }, [AllNotification]);

  const fetchAllReadNotification = () => {
    dispatch(readAllNotification()).then((res) => {
      if(!res.error){
        dispatch(getAllNotification());
      }
    });
  }

  return (
    <Grid pt={2} pb={2} container flexDirection="column">
      <Grid item container justifyContent="space-between" p={1}>
        <Typography
          sx={{
            color: "#2B2B2B",
            fontWeight: "500",
            fontSize: "1.25rem",
            lineHeight: "30px",
          }}
        >
          {t("notification")}
        </Typography>
        <Typography
          sx={{
            color: "#2B2B2B",
            fontWeight: "500",
            fontSize: "1.25rem",
            lineHeight: "30px",
            cursor: "pointer",
          }}
          onClick={fetchAllReadNotification}
        >
          {t("read_all")}
        </Typography>
      </Grid>
      <Grid item sx={{ background: "" }}>
        {topNotification?.length > 0 &&
          topNotification.map((notification, index) => (
            <Notifications key={index} notification={notification} />
          ))}
      </Grid>
      <Grid item textAlign="center" sx={{ background: "" }}>
        <Typography
          onClick={() => navigate("/notification")}
          sx={{
            color: "#2B2B2B",
            fontWeight: "600",
            fontSize: "1.25rem",
            lineHeight: "30px",
            cursor: "pointer",
          }}
        >
          {t("view_all_notification")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NotificationsPanel;
