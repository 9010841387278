import {
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import filterIcon from "../../assets/images/filter-icon.svg";
import { useTranslation } from "react-i18next";

export default function SingleSelectCheckmarks({ data, handleOrderingChange }) {
  const [personName, setPersonName] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {t} = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (item) => {
    setAnchorEl(null);
    setPersonName(item);
  };

  useEffect(() => {
    if (personName) {
      handleOrderingChange(personName);
    }
  }, [personName]);

  const handleChange = (event) => {
    setPersonName(event.target.value);
  };

  return (
    <div style={{width: "100px"}}>
      {t("status")}
      <IconButton sx={{ padding: "0 5px" }} onClick={handleClick}>
        <img src={filterIcon} alt="" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {data.map((item) => (
          <MenuItem key={item.id} onClick={() => handleClose(item)}>
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
