import { Divider, Typography } from "@mui/material"

const AddInstructionLabel = ({label}) => {
  return (
    <>
      <Divider
            orientation="horizontal"
            variant="fullWidth"
            sx={{
              background: "#222222",
              margin: "1rem 0",
              width: "100%",
            }}
          />
          <Typography
            p={1}
            mb={2}
            sx={{
              fontSize: "1.125rem",
              fontWeight: "600",
              color: "black",
              backgroundColor: "",
              borderRadius: "100px",
              lineHeight: "18px",
            }}
          >
            {label}
          </Typography>
    </>
  )
}

export default AddInstructionLabel