import React from "react";
import { ReactComponent as SuccessIcon } from "../../../assets/images/Group.svg";
import { Button, Container, Grid, Typography, styled } from "@mui/material";
import { red } from "@mui/material/colors";
import { Navigate, useNavigate } from "react-router";

const StyledSuccessLogo = styled(SuccessIcon)({
  height: "6rem",
  width: "6rem",
});

const ResetSuccess = () => {
  const navigate = useNavigate();
  return (
    <Grid
      mt={20}
      textAlign="center"
      container
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      className="form-container"
      gap={2}
    >
      <Grid item>
        <StyledSuccessLogo />
      </Grid>
      <Grid item pt={1}>
        <Typography
          sx={{
            fontWeight: "600",
            fontFamily: "Poppins",
            fontSize: "1.75rem",
            lineHeight: "41px",
          }}
        >
          Successful
        </Typography>
      </Grid>
      <Grid
        item
        sx={{
          fontWeight: "600",
          fontFamily: "Poppins",
          fontSize: "1.75rem",
          lineHeight: "41px",
        }}
      >
        <Typography
          sx={{
            fontWeight: "400",
            fontFamily: "Poppins",
            fontSize: "1rem",
            lineHeight: "24px",
            color: "#2B2B2B",
          }}
        >
          You have successfully reset your password. <br />
          Please click the button below to Login
        </Typography>
      </Grid>
      <Grid item>
        <Button
          sx={{
            width: "19.8125rem",
            height: "2.5rem",
            background: "#7363F8",
            borderRadius: "6.25rem",
            color: "white",
            "&:hover": { background: "#7363F8" },
          }}
          onClick={() => navigate("/login")}
        >
          Go Back to Login
        </Button>
      </Grid>
    </Grid>
  );
};

export default ResetSuccess;
