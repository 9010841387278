import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Loader from "../components/Loader/Loader";

export const RequireAuth = ({ children }) => {
  const { isLoggedIn, api, initialLoading } = useSelector(
    (state) => state.auth
  );
  if (isLoggedIn) {
    return children;
  }
  if (initialLoading || api.loading) {
    return <Loader />;
  }
  return <Navigate to="/login" />;
};
