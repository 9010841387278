import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useTranslation } from "react-i18next";

const InstructionCard = ({ status, pickupComments }) => {
  const { t, i18n } = useTranslation();
  dayjs.extend(utc);
  dayjs.extend(timezone);

  return (
    <>
      {!(pickupComments?.gate === "" && pickupComments?.comment === "") && (
        <Grid
          p={2}
          mb={2}
          gap={0.5}
          container
          flexDirection="row"
          sx={{
            border: "1.5px solid #989CBB",
            borderRadius: "1.25rem",
          }}
        >
          <Grid item container>
            {pickupComments?.gate !== "" && (
              <>
                <Grid item container flexDirection="column">
                  <Grid item xs={8}>
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        fontWeight: "500",
                        color: "#989CB8",
                        overflowWrap: "anywhere",
                        // wordWrap: "break-word",
                        lineHeight: "18px",
                      }}
                    >
                      {t("gate_no")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    pt={0.5}
                    mt={0.5}
                    textAlign="right"
                    sx={{ background: "" }}
                    xs="auto"
                  >
                    <Typography
                      sx={{
                        color: "#989CB8",
                        fontWeight: "500",
                        fontSize: "0.75rem",
                        lineHeight: "10px",
                      }}
                    >
                      {dayjs(pickupComments?.time)
                        .format("MMM D, YYYY HH:mm")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item pb={2}>
                  <Typography
                    sx={{
                      fontSize: "0.875rem",
                      fontWeight: "500",
                      color: "#000000",
                      lineHeight: "21px",
                      overflowWrap: "anywhere",
                    }}
                  >
                    {pickupComments?.gate}
                  </Typography>
                </Grid>
              </>
            )}
            </Grid>
            <Grid item container>
            {pickupComments.comment !== "" && (
              <>
                <Grid item container flexDirection="column">
                  <Grid item xs={8}>
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        fontWeight: "500",
                        color: "#989CB8",
                        lineHeight: "18px",
                      }}
                    >
                      {["declined", "loading_paused", "on_hold"].includes(
                        status
                      )
                        ? t("reason")
                        : t("instruction")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    pt={0.5}
                    mt={0.5}
                    textAlign="right"
                    sx={{ background: "" }}
                    xs="auto"
                  >
                    <Typography
                      sx={{
                        color: "#989CB8",
                        fontWeight: "500",
                        fontSize: "0.75rem",
                        lineHeight: "10px",
                      }}
                    >
                      {dayjs(pickupComments?.time)
                        .format("MMM D, YYYY HH:mm")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item pb={2}>
                  <Typography
                    sx={{
                      fontSize: "0.875rem",
                      fontWeight: "500",
                      color: "#000000",
                      lineHeight: "21px",
                      overflowWrap: "anywhere",
                    }}
                  >
                    {pickupComments?.comment}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default InstructionCard;
