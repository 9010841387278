import React, { useEffect, useState } from "react";
import CustomizedTables from "../../components/DashboardTable";
import { Grid } from "@mui/material";
import CustomToggleButton from "../../components/CustomToggleButton";
import PageLayout from "../../Layouts/PageLayouts";
import { useDispatch } from "react-redux";
import { getAllOrders, setCurrentOrderType } from "../../store/reducers/orders";
import { useSelector } from "react-redux";
import { buttonNamesForOrderTypes } from "../../utils/constants/order";
import { useTranslation } from "react-i18next";


const DashBoardPage = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const { loading } = useSelector(
    (state) => state.orders.getAllOrdersApi
  );

  const  currentUserType  = useSelector((state) => state.orders.currentUserType) || parseInt(localStorage.getItem("currentUserType") || 0);
  const currentOrderType = useSelector((state) => state.orders.currentOrderType) || parseInt(localStorage.getItem("currentOrderType") || 0);

  const [allOrdersData, setAllOrdersData] = useState();
  const [statusConfig, setStatusConfig] = useState({
    params: {
      order: currentUserType === 0 ? "checked_in_orders" : "drop_checked_in_orders",
      search: "",
      page_size: 20,
      page: 1,
      filter: "",
    },
  });
  
  let searchTimer;

  const [openDeclineReasonModal, setOpenDeclineReasonModal] = useState(false);
  const onCloseDeclineReason = () => setOpenDeclineReasonModal(false);
  const [selectedToggleButton, setSelectedToggleButton] = useState(currentOrderType);

  const [alignment, setAlignment] = useState(currentOrderType);

  const fetchData = async (statusConfig) => {
    dispatch(getAllOrders(statusConfig)).then((res) => {
      if(!res.error){
        setAllOrdersData(res.payload);
      }
    });
  };

  useEffect(() => {
    const config = { ...statusConfig };
    config.params.filter = "";
    config.params.page = 1;
    if(currentUserType === 0){
      if (selectedToggleButton === 0) {
        config.params.order = "checked_in_orders";
      } else if (selectedToggleButton === 1) {
        config.params.order = "ongoing_orders";
      } else {
        config.params.order = "completed_orders";
      }
    }
    else{
      if (selectedToggleButton === 0) {
        config.params.order = "drop_checked_in_orders";
      } else if (selectedToggleButton === 1) {
        config.params.order = "drop_ongoing_orders";
      } else {
        config.params.order = "drop_completed_orders";
      }
    }
    dispatch(setCurrentOrderType(selectedToggleButton));
    setStatusConfig(config);
  }, [selectedToggleButton, currentUserType]);

  useEffect(() => {
    fetchData(statusConfig);
  }, [statusConfig]);

  const handleChangeSearch = (event) => {
    clearTimeout(searchTimer);
    searchTimer = setTimeout(() => {
      const config = { ...statusConfig };
      config.params.search = event.target.value;
      setStatusConfig(config);
    }, 1000);
  };

  return (
    <>
      <PageLayout
        headerTitle={t("dashboard_title")}
        showBackBtn={false}
        handleChange={handleChangeSearch}
        fetchDashboardData = {fetchData}
        statusConfig = {statusConfig}
      >
        <Grid container mt={4} sx={{ position: "fixed" }}>
          <Grid item pt={2} sx={{ width: "40%", paddingLeft: "1rem" }}>
            <CustomToggleButton
              buttonNames={buttonNamesForOrderTypes}
              disabled={false}
              setSelectedToggleButton={(selectedButtonId) =>
                setSelectedToggleButton(selectedButtonId)
              }
              alignment={alignment}
              setAlignment={setAlignment}
            />
          </Grid>
          <Grid item pt={2} paddingLeft="1rem">
            <CustomizedTables
              loading={loading}
              refetch={fetchData}
              allOrdersData={allOrdersData}
              selectedToggleButton={selectedToggleButton}
              setSelectedToggleButton={setSelectedToggleButton}
              setAlignment={setAlignment}
              statusConfig={statusConfig}
              setStatusConfig={setStatusConfig}
              currentUserType = {currentUserType}
            />
          </Grid>
        </Grid>
      </PageLayout>
    </>
  );
};

export default DashBoardPage;
