import React from "react";
import { Divider, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SpecificRemark from "./SpecificRemark";

const DriverRemark = ({ driverRemark }) => {
  const {t} = useTranslation();

  return (
    <>
      <Typography
        p={1}
        mb={2}
        sx={{
          fontSize: "1.125rem",
          fontWeight: "600",
          color: "black",
          backgroundColor: "",
          borderRadius: "100px",
          lineHeight: "18px",
        }}
      >
        {t("driver_remarks")}
      </Typography>
      <Grid
        p={2}
        mb={2}
        gap={0.5}
        container
        flexDirection="row"
        sx={{
          border: "1.5px solid #989CBB",
          borderRadius: "1.25rem",
        }}
      >
        {driverRemark?.map((remark, index) => (
          <SpecificRemark key={index} remark = {remark}/>
        ))}
      </Grid>
      <Divider
        orientation="horizontal"
        variant="fullWidth"
        sx={{
          background: "#222222",
          margin: "1rem 0",
          width: "100%",
        }}
      />
    </>
  );
};

export default DriverRemark;
