import React from "react";

const InfoWindowContent = ({ profile_picture, name, role }) => {
  return (
    <div className="info-window-container">
      <img
        className="profile-photo"
        alt="profile-picture"
        src={profile_picture}
      />
      <div className="content">
        <p className="name">{name}</p>
        <p className="role">{role}</p>
      </div>
    </div>
  );
};

export default InfoWindowContent;
