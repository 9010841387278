import React, { useState, useRef, useEffect } from "react";
import { Container, Typography } from "@mui/material";
import { TextField, Button, CircularProgress } from "@mui/material";
import BackButton from "../../assets/images/back.svg";
import { unwrapResult } from "@reduxjs/toolkit";

import Logo from "../../components/logo/logo";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  resetPasswordRequest,
  verifyOtp,
  resetMessages,
} from "../../store/reducers/auth";
import { useTranslation } from "react-i18next";

export const EmailCheck = () => {
  const navigation = useNavigate();
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);
  const [timer, setTimer] = useState(59);
  const [showTimer, setShowTimer] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();
  const { verifyOtpApi } = useSelector((state) => state.auth);
  const {t} = useTranslation();

  useEffect(() => {
    let interval = setInterval(() => {
      if (showTimer) {
        setTimer((prevTimer) => {
          if (prevTimer === 0) {
            clearInterval(interval);
            setShowTimer(false);
            return prevTimer;
          } else {
            return prevTimer - 1;
          }
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [showTimer]);

  useEffect(() => {
    dispatch(resetMessages());
  }, []);

  const handleChange = (event, index) => {
    const newOtp = [...otp];
    newOtp[index] = event.target.value;
    setOtp(newOtp);

    if (event.target.value !== "" && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && index > 0 && otp[index] === "") {
      event.preventDefault(); // Prevent the default behavior of the Backspace key
      inputRefs.current[index - 1].focus();
    }
  };

  const onSubmit = () => {
    if (otp.length === 6) {
      dispatch(
        verifyOtp({
          email: location.state?.email,
          otp: otp.join(""),
        })
      )
        .then(unwrapResult)
        .then((data) => {
          navigation("/newpassword", {
            state: {
              token: data.token,
              uidb64: data.uidb64,
            },
          });
        });
    }
  };

  return (
    <Container maxWidth="md">
      <div className="logo-container">
        <Logo />
      </div>
      <div className="form-container">
        <div className="header-back-button">
          <span className="backbutton" onClick={() => navigation(-1)}>
            <img src={BackButton} alt="" />
          </span>
          <Typography
            variant="h2"
            fontWeight="600"
            color="#2B2B2B"
            fontSize="28px"
            textAlign="center"
          >
            {t("check_email")}
          </Typography>
        </div>
        <div className="form">
          <form>
            <div className="input-group otp">
              {otp.map((value, index) => (
                <TextField
                  key={index}
                  value={value}
                  onChange={(event) => handleChange(event, index)}
                  onKeyDown={(event) => handleKeyDown(event, index)}
                  inputRef={(ref) => (inputRefs.current[index] = ref)}
                  variant="standard"
                  size="small"
                  type="text"
                  inputProps={{
                    maxLength: 1,
                    style: {
                      textAlign: "center",
                      fontSize: "1.5rem",
                      letterSpacing: "0.5rem",
                    },
                  }}
                />
              ))}
            </div>
            <div className="input-group button" style={{ marginBottom: 16 }}>
              <Button variant="standard" color="primary" onClick={onSubmit}>
                {verifyOtpApi.loading && (
                  <CircularProgress
                    size={18}
                    sx={{ color: "#ffff", marginRight: "10px" }}
                  />
                )}
                {t("verify")}
              </Button>
              <span className="error-message">
                {!verifyOtpApi.loading && verifyOtpApi.error}
              </span>
            </div>
            <div className="input-group not-account">
              <span>
                {t("not_received_otp")}?{" "}
                <a
                  onClick={() =>
                    timer === 0 && [
                      dispatch(
                        resetPasswordRequest({
                          email: location.state?.email,
                        })
                      ),
                      setTimer(59),
                      setShowTimer(true),
                    ]
                  }
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {t("resend")}
                </a>
              </span>
            </div>
            <div className="timer">00:{timer < 10 ? `0${timer}` : timer}</div>
          </form>
        </div>
      </div>
    </Container>
  );
};
