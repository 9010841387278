import React, { useEffect, useState } from "react";
import { Container, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { Formik } from "formik";
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
  InputLabel,
  NativeSelect,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Logo from "../../components/logo/logo";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { loginRequest, resetMessages } from "../../store/reducers/auth";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  ChevronDown,
  Visibility,
  VisibilityOff,
} from "../../components/inputFields";
import { toast } from "react-toastify";
import { getAllNotification } from "../../store/reducers/notification";
import { deleteToken } from "firebase/messaging";
import { messaging } from "../../firebase";

export const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { api } = useSelector((state) => state.auth);
  const [language, setLanguage] = useState(i18n.language);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    dispatch(resetMessages());
  }, []);

  const fetchAllNotification = () => {
    dispatch(getAllNotification())
  };

  const handleLogin = (values) => {
    // deleting fcmtoken for safe side if by default logout and not deleted
    deleteToken(messaging);
    localStorage.clear();
    dispatch(
      loginRequest({
        username: values.email,
        password: values.password,
        user_type: "Distributor",
      })
    )
      .then((res) => {
        if (!res?.error) {
          console.log("Logged in successfully");
          toast.success(t("logged_in_successfully"));
          fetchAllNotification();
          navigation("/dashboard");
        } else {
          toast.error(res?.error?.message || t("something_went_wrong"));
        }
      })
      .catch((err) => {
        console.log("Login err", err);
        // alert(err);
      });
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
  }

  return (
    <Container maxWidth="md">
      <div className="logo-container">
        <Logo />
      </div>
      <div className="form-container">
        <Typography
          variant="h2"
          fontWeight="600"
          color="#2B2B2B"
          fontSize="28px"
          textAlign="center"
        >
          {t("login")}
        </Typography>
        <div className="form">
          <Formik
            initialValues={{ email: "", password: "", language: "en" }}
            validationSchema={Yup.object().shape({
              password: Yup.string().required().label("Password"),
              email: Yup.string().email().required().label("Email"),
            })}
            onSubmit={handleLogin}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="input-group">
                  <TextField
                    fullWidth
                    id="email"
                    label={t("email")}
                    variant="standard"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  <span className="error-message">
                    {touched.email && errors.email}
                  </span>
                </div>

                <div className="input-group">
                  <TextField
                    fullWidth
                    label={t("password")}
                    type={showPassword ? "text" : "password"}
                    name="password"
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  <span className="error-message">
                    {touched.password && errors.password}
                  </span>
                </div>
                <div>
                <FormControl sx={{minWidth: 90}}>
                  <Select
                    value={language}
                    onChange={handleLanguageChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value={'en'}>Eng</MenuItem>
                    <MenuItem value={'es'}>Spanish</MenuItem>
                  </Select>
                </FormControl>
                </div>
                <div className="input-group">
                  <span className="forgot-password">
                    <Link to="/forgotpassword">{t("forgot_password")}?</Link>
                  </span>
                </div>
                <div
                  className="input-group button"
                  style={{ marginBottom: 16 }}
                >
                  <Button type="submit" variant="standard" color="primary">
                    {api.loading && (
                      <CircularProgress
                        size={18}
                        sx={{
                          color: "#ffff",
                          marginRight: "10px",
                        }}
                      />
                    )}
                    {t("login")}
                  </Button>
                  {/* <span className="error-message">
                    {!api.loading && api.error}
                  </span> */}
                </div>
                <div className="input-group not-account">
                  <span>
                    {t("dont_have_account")}?{" "}
                    <Link to="/register">{t("sign_up")}</Link>
                  </span>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Container>
  );
};
