import { ToggleButton, ToggleButtonGroup, styled } from "@mui/material";
import { variables } from "../../assets/scss/variables.js";

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  // background: "#D7D7CB",
  background: "#E7E7E7",
  height: "auto",
  padding: "0.3rem",
  borderRadius: "4rem",
});
export const StyledToggleButton = styled(ToggleButton)({
  border: "none",
  borderRadius: "4rem !important",
  color: "#000000",

  textTransform: "capitalize",
  "&.Mui-selected": {
    color: "white",
    background: variables.primaryColor,
    // background: "green",
    "&:hover": {
      // background: "none",
      background: variables.primaryColor,
    },
  },
  // "&:not(.Mui-selected)": {
  //   background: "transparent",
  // },
  "&:hover": {
    // background: "gray",
    background: "none",
  },
  "&.Mui-disabled": {
    opacity: "0.4",
    border: "none",
    // background: "",
    // color: "#c0c0c0",
  },
});
