import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Select,
  MenuItem,
} from "@mui/material";
import { allCountries } from "country-telephone-data";
import { useDispatch, useSelector } from "react-redux";
import Location from "../../assets/images/location.svg";
import Hours from "../../assets/images/time.svg";
import Terms from "../../assets/images/terms.svg";
import FeedbackIcon from "../../assets/images/feedback.svg";
import Next from "../../assets/images/next.svg";
import Dollar from "../../assets/images/dollar.svg";
import ProfileUpload, {
  convertToBase64,
} from "../../components/profileupload/ProfileUpload";
import LocationModal from "../../components/locationmodal/LocationModal";
import HoursModal from "../../components/hoursmodal/HoursModal";
import DeleteModal from "../../components/deletemodal/DeleteModal";
import LogoutModal from "../../components/logoutmodal/LogoutModal";
import ChangePasswordModal from "../../components/changepassword/ChangePasswordModal";
import * as Yup from "yup";
import {
  postDistributor,
  updateDistributor,
  setProfileDate,
} from "../../store/reducers/auth";
import { useLocation, useNavigate } from "react-router";
import { Alert, Typography } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { Link } from "react-router-dom";

import { toast } from "react-toastify";
import PageLayout from "../../Layouts/PageLayouts";
import { useTranslation } from "react-i18next";

const getTime = (date) => {
  var myDate = new Date(date);
  var hours = myDate.getHours();
  var minutes = myDate.getMinutes();
  var amPm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert hours to 12-hour format
  var formattedTime =
    hours.toString().padStart(2, "0") +
    ":" +
    minutes.toString().padStart(2, "0") +
    amPm;
  return formattedTime;
};

const dates = [
  { id: 1, day_of_week: "Mon" },
  { id: 2, day_of_week: "Tue" },
  { id: 3, day_of_week: "Wed" },
  { id: 4, day_of_week: "Thu" },
  { id: 5, day_of_week: "Fri" },
  { id: 6, day_of_week: "Sat" },
  { id: 7, day_of_week: "Sun" },
];

export const Profile = () => {
  const { postDistributorApi, user, profile } = useSelector(
    (state) => state.auth
  );
  const { t } = useTranslation();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalOpen3, setModalOpen3] = useState(false);
  const location = useLocation();
  const [showAlert, setShowAlert] = useState(false);
  const [image, setImage] = useState("");
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      phone_number: "",
      // warehouse_phone_number: "",
      country_code: "",
      distributor_name: "",
      warehouse_name: "",
      address: "",
      email: "",
      logo: "",
      latitude: "",
      longitude: "",
      schedules: [],
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().label("First name").required(),
      last_name: Yup.string().label("Last name").required(),
      phone_number: Yup.number()
        .typeError("Phone number must be a number")
        .label("Phone number")
        .required(),
      // warehouse_phone_number: Yup.string()
      //   .label("Warehouse phone number")
      //   .required(),
      distributor_name: Yup.string()
        .label("Distributor/Receiver name")
        .required(),
      warehouse_name: Yup.string().label("Warehouse name").required(),
      address: Yup.string().label("Address").required(),
      logo: Yup.string().label("Logo").required(),
      country_code: Yup.string().label("Country code").required(),
    }),

    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      if (user?.profile_data_id) {
        dispatch(
          updateDistributor({
            ...values,
            id: user?.profile_data_id,
            schedules: values.schedules.map(({ checked, ...s }) => ({
              ...s,
              opening_hour: isNaN(Date.parse(s.opening_hour))
                ? s.opening_hour
                : getTime(s.opening_hour),
              closing_hour: isNaN(Date.parse(s.closing_hour))
                ? s.closing_hour
                : getTime(s.closing_hour),
            })),
          })
        )
          // .then(unwrapResult)
          .then((res) => {
            if(!res.error){
              toast.success(t("profile_updated_success"));
              // setShowAlert(true);
              navigation("/dashboard");
            }
          });
      } else {
        dispatch(
          postDistributor({
            ...values,
            schedules: values.schedules.map(({ checked, ...s }) => ({
              ...s,
              opening_hour: isNaN(Date.parse(s.opening_hour))
                ? s.opening_hour
                : getTime(s.opening_hour),
              closing_hour: isNaN(Date.parse(s.closing_hour))
                ? s.closing_hour
                : getTime(s.closing_hour),
            })),
          })
        )
          // .then(unwrapResult)
          .then((res) => {
            // setShowAlert(true);
            if(!res.error){
              toast.success(t("profile_updated_success"));
              navigation("/dashboard");
            }
          });
      }
    },
  });

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = formik;

  useEffect(() => {
    formik.setValues({
      first_name: user?.first_name || "",
      last_name: user?.last_name || "",
      phone_number: user?.phone_number || "",
      // warehouse_phone_number: user?.warehouse_phone_number || "",
      country_code: user?.country_code || "",
      distributor_name: user?.distributor_name || "",
      warehouse_name: user?.warehouse_name || "",
      address: user?.address,
      email: user?.email || "",
      logo: user?.logo || "",
      latitude: user?.latitude || "",
      longitude: user?.longitude || "",
      schedules: user?.schedules || [],
    });
    setImage(user?.logo);
  }, [user]);

  useEffect(() => {
    if (location.state?.address && Object.keys(profile).length > 0) {
      formik.setValues({
        ...formik.initialValues,
        ...profile,
        address: location.state?.address,
        latitude: location.state?.location?.lat,
        longitude: location.state?.location?.lng,
      });
    }
    setImageAsync(profile.logo);
  }, [location?.state?.address, profile]);

  const setImageAsync = async (logo) => {
    let image = logo;
    if (logo instanceof Blob) {
      image = await convertToBase64(logo);
    }
    setImage(image);
  };

  const handleAddLocation = () => {
    const addedAddress = localStorage.getItem("@location");
    if (addedAddress && JSON.parse(addedAddress)) {
      navigation("/location");
    } else {
      localStorage.setItem("@location", JSON.stringify(true));
      setModalOpen2(true);
    }
  };

  const handleModalClose2 = () => {
    setModalOpen2(false);
  };

  const handleModalOpen3 = () => {
    setModalOpen3(true);
  };

  const handleModalClose3 = () => {
    setModalOpen3(false);
  };

  const uploadImageHandler = (file, image) => {
    setFieldValue("logo", file);
    setProfileDate({
      ...profile,
      logo: file,
    });
    setImage(image);
  };

  // console.log(values)
  const formOnChangeHandler = (e) => {
    if (e.target.name) {
      dispatch(
        setProfileDate({
          ...profile,
          [e.target.name]:
            e.target.type === "file" ? e.target.files[0] : e.target.value,
        })
      );
    }
  };

  const getOpeningHours = ({ schedules, date }) => {
    return isNaN(schedules.find((s) => s.day_of_week === date)?.opening_hour)
      ? schedules.find((s) => s.day_of_week === date)?.opening_hour
      : getTime(schedules.find((s) => s.day_of_week === date)?.opening_hour);
  };

  const getClosingHours = ({ schedules, date }) => {
    return isNaN(schedules.find((s) => s.day_of_week === date)?.closing_hour)
      ? schedules.find((s) => s.day_of_week === date)?.closing_hour
      : getTime(schedules.find((s) => s.day_of_week === date)?.closing_hour);
  };

  const renderDates = () => {

    return dates.map((date) => (
      <div key={date.id} style={{ display: "flex" }}>
        <div style={{ width: "20%" }}>{date.day_of_week.toUpperCase()}</div>
        <div>
          {values.schedules.some((s) => s.day_of_week === date.day_of_week)
            ? getOpeningHours({ schedules: values.schedules, date: date.day_of_week }) + " - " + getClosingHours({ schedules: values.schedules, date: date.day_of_week })
            : "Closed"}
        </div>
      </div>
    ));
  };

  return (
    <PageLayout headerTitle={t("my_profile_title")} showSearchField={false}>
      <div className="profile-data">
        <div className="profile-form">
          <div className="form">
            <form onSubmit={handleSubmit} onChange={formOnChangeHandler}>
              <ProfileUpload onChange={uploadImageHandler} image={image} />
              <span className="error-message">
                {touched.logo && errors.logo}
              </span>
              <div className="input-group">
                <TextField
                  fullWidth
                  id="fname"
                  label={t("first_name")}
                  variant="standard"
                  name="first_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.first_name}
                />
                <span className="error-message">
                  {touched.first_name && errors.first_name}
                </span>
              </div>

              <div className="input-group">
                <TextField
                  fullWidth
                  id="lname"
                  label={t("last_name")}
                  variant="standard"
                  name="last_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.last_name}
                />
                <span className="error-message">
                  {touched.last_name && errors.last_name}
                </span>
              </div>
              <div className="input-group">
                <TextField
                  fullWidth
                  id="dname"
                  label={
                    t("distributor") + "/" + t("receiver") + " " + t("name")
                  }
                  variant="standard"
                  name="distributor_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.distributor_name}
                />
                <span className="error-message">
                  {touched.distributor_name && errors.distributor_name}
                </span>
              </div>

              <div className="input-group">
                <TextField
                  fullWidth
                  id="whname"
                  label={t("warehouse_name")}
                  variant="standard"
                  name="warehouse_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.warehouse_name}
                />
                <span className="error-message">
                  {touched.warehouse_name && errors.warehouse_name}
                </span>
              </div>

              <div className="input-group">
                <div className="phone-number">
                  <Select
                    value={values.country_code}
                    onChange={(e) => {
                      handleChange(e);
                      formOnChangeHandler(e);
                    }}
                    name="country_code"
                    renderValue={(selected) => {
                      return selected
                        ? "+" +
                            allCountries?.find(
                              (country) => country.dialCode === selected
                            )?.dialCode
                        : "";
                    }}
                  >
                    {allCountries?.map((country) => (
                      <MenuItem key={country.iso2} value={country.dialCode}>
                        {`${country.iso2.toUpperCase()} (+${country.dialCode})`}
                      </MenuItem>
                    ))}
                  </Select>
                  <TextField
                    fullWidth
                    id="pnumber"
                    label={t("phone_number")}
                    variant="standard"
                    name="phone_number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone_number}
                  />
                </div>
                <span className="error-message">
                  {(touched.phone_number && errors.phone_number) ||
                    (touched.country_code && errors.country_code)}
                </span>
              </div>

              <div className="input-group">
                <Typography fontSize={14} color="#2B2B2B" fontWeight={400}>
                  {t("email")}
                </Typography>
                <Typography variant="caption" fontSize={16}>
                  {values.email}
                </Typography>
              </div>

              <div className="location-container">
                <h3>{t("location")}</h3>
                <h5>{t("location_placeholder")}</h5>
                <div className="address">
                  <span>{("what will show in location", values?.address)}</span>
                </div>
                <span className="error-message">
                  {touched.address && errors.address}
                </span>
                <div className="set-location" onClick={handleAddLocation}>
                  <span>
                    <img src={Location} alt="" />
                    {t("set_location")}
                  </span>
                </div>
              </div>
              <div className="operations-container">
                <h3>{t("hours_of_operation")}</h3>
                <h5>{t("hours_placeholder")}</h5>
                <div className="schedule-container">{renderDates()}</div>
                <div className="set-location" onClick={handleModalOpen3}>
                  <span>
                    <img src={Hours} alt="" />
                    {t("set_hours")}
                  </span>
                </div>
              </div>
              <div className="input-group button">
                <Button
                  type="submit"
                  variant="standard"
                  color="primary"
                  disabled={postDistributorApi.loading}
                >
                  {postDistributorApi.loading && (
                    <CircularProgress
                      size={18}
                      style={{ marginRight: 10, color: "#ffff" }}
                    />
                  )}
                  {t("save")} & {t("continue")}
                </Button>

                <span className="error-message">
                  {!postDistributorApi.loading && postDistributorApi.error}
                </span>
              </div>

              <div className="account-settings">
                <h3>{t("account_setting")}</h3>
                <div className="settings-container">
                  <ul>
                    <Link to="/terms-condition">
                      <li>
                        <span>
                          <img src={Terms} alt="" /> {t("terms_and_conditions")}
                        </span>
                        <span>
                          <img src={Next} alt="" />
                        </span>
                      </li>
                    </Link>
                    <Link to="/privacy-policy">
                      <li>
                        <span>
                          <img src={Terms} alt="" /> {t("privacy_policy")}
                        </span>
                        <span>
                          <img src={Next} alt="" />
                        </span>
                      </li>
                    </Link>
                    <Link to="/feedback">
                      <li>
                        <span>
                          <img src={FeedbackIcon} alt="" /> {t("send_feedback")}
                        </span>
                        <span>
                          <img src={Next} alt="" />
                        </span>
                      </li>
                    </Link>
                    <Link to="/subscription">
                      <li>
                        <span>
                          <img src={Dollar} alt="" /> {t("my_subscription")}
                        </span>
                        <span>
                          <img src={Next} alt="" />
                        </span>
                      </li>
                    </Link>

                    <ChangePasswordModal />
                    <DeleteModal />
                    <LogoutModal />
                  </ul>
                </div>
              </div>

              <LocationModal open={modalOpen2} onClose={handleModalClose2} />
              <HoursModal
                open={modalOpen3}
                onClose={handleModalClose3}
                schedules={user?.schedules || []}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                setProfile={(schedules) =>
                  dispatch(setProfileDate({ ...profile, schedules }))
                }
              />
            </form>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
